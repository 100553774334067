import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/fuelling/Development/sappenin/ilpv4-dev-website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "marty-connector-testilpv4-devmarty"
    }}>{`Marty Connector (`}<inlineCode parentName="h1">{`test.ilpv4-dev.marty`}</inlineCode>{`)`}</h1>
    <p>{`Marty is a `}<a parentName="p" {...{
        "href": "https://github.com/interledger/rfcs/blob/44deb112e51db27e89bf1b02d115c272dda2cddb/0034-connector-requirements/0034-connector-requirements.md#link-relations"
      }}>{`Tier1`}</a>{`
Connector implemented in Java via the `}<a parentName="p" {...{
        "href": "https://github.com/sappenin/java-ilp-connector"
      }}>{`java-ilp-connector`}</a>{` project.`}</p>
    <h2 {...{
      "id": "testnet-configuration"
    }}>{`Testnet Configuration`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`URL`}</strong>{`: `}<inlineCode parentName="li">{`https://marty-dot-ilpv4-dev.appspot.com`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`ILP Address`}</strong>{`: `}<inlineCode parentName="li">{`test.ilpv4-dev.marty`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "martys-peers"
    }}>{`Marty's Peers`}</h2>
    <h3 {...{
      "id": "emmett"
    }}>{`Emmett`}</h3>
    <p>{`Marty peers with `}<a parentName="p" {...{
        "href": "emmett-connector"
      }}>{`Emmett`}</a>{` using `}<a parentName="p" {...{
        "href": "https://github.com/interledger/rfcs/blob/master/0035-ilp-over-http/0035-ilp-over-http.md"
      }}>{`Ilp-over-Http`}</a>{`.`}</p>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="498" height="79">
      <path d="M 2.878906 13.319336 L 2.878906 6.412109 L 6.729980 6.412109 L 6.729980 7.335938 L 3.738281 7.335938 L 3.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 26.319336 L 2.878906 12.300781 L 3.738281 12.300781 L 3.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 39.319336 L 2.878906 25.300781 L 3.738281 25.300781 L 3.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 52.319336 L 2.878906 38.300781 L 3.738281 38.300781 L 3.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 65.319336 L 2.878906 51.300781 L 3.738281 51.300781 L 3.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 72.335938 L 2.878906 64.300781 L 3.738281 64.300781 L 3.738281 71.412109 L 6.729980 71.412109 L 6.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 6.892578 72.335938 L 6.892578 71.412109 L 13.729980 71.412109 L 13.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 13.892578 72.335938 L 13.892578 71.412109 L 20.729980 71.412109 L 20.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 20.892578 72.335938 L 20.892578 71.412109 L 27.729980 71.412109 L 27.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 27.892578 72.335938 L 27.892578 71.412109 L 34.729980 71.412109 L 34.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 34.892578 72.335938 L 34.892578 71.412109 L 41.729980 71.412109 L 41.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 41.892578 72.335938 L 41.892578 71.412109 L 48.729980 71.412109 L 48.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 48.892578 72.335938 L 48.892578 71.412109 L 55.729980 71.412109 L 55.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 55.892578 72.335938 L 55.892578 71.412109 L 62.729980 71.412109 L 62.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 62.892578 72.335938 L 62.892578 71.412109 L 69.729980 71.412109 L 69.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 69.892578 72.335938 L 69.892578 71.412109 L 76.729980 71.412109 L 76.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 76.892578 72.335938 L 76.892578 71.412109 L 83.729980 71.412109 L 83.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 83.892578 72.335938 L 83.892578 71.412109 L 90.729980 71.412109 L 90.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 90.892578 72.335938 L 90.892578 71.412109 L 97.729980 71.412109 L 97.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 97.892578 72.335938 L 97.892578 71.412109 L 104.729980 71.412109 L 104.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 104.892578 72.335938 L 104.892578 71.412109 L 111.729980 71.412109 L 111.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 111.892578 72.335938 L 111.892578 71.412109 L 118.729980 71.412109 L 118.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 118.892578 72.335938 L 118.892578 71.412109 L 125.729980 71.412109 L 125.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 125.892578 72.335938 L 125.892578 71.412109 L 132.729980 71.412109 L 132.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 132.892578 72.335938 L 132.892578 71.412109 L 139.729980 71.412109 L 139.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 139.892578 72.335938 L 139.892578 71.412109 L 146.729980 71.412109 L 146.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.892578 72.335938 L 146.892578 71.412109 L 153.729980 71.412109 L 153.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 153.892578 72.335938 L 153.892578 71.412109 L 160.729980 71.412109 L 160.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 160.892578 72.335938 L 160.892578 71.412109 L 167.729980 71.412109 L 167.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 167.892578 72.335938 L 167.892578 71.412109 L 174.729980 71.412109 L 174.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 174.892578 72.335938 L 174.892578 71.412109 L 177.878906 71.412109 L 177.878906 64.300781 L 178.738281 64.300781 L 178.738281 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 72.335938 L 317.878906 64.300781 L 318.738281 64.300781 L 318.738281 71.412109 L 321.729980 71.412109 L 321.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 321.892578 72.335938 L 321.892578 71.412109 L 328.729980 71.412109 L 328.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 328.892578 72.335938 L 328.892578 71.412109 L 335.729980 71.412109 L 335.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 335.892578 72.335938 L 335.892578 71.412109 L 342.729980 71.412109 L 342.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 342.892578 72.335938 L 342.892578 71.412109 L 349.729980 71.412109 L 349.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 349.892578 72.335938 L 349.892578 71.412109 L 356.729980 71.412109 L 356.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 356.892578 72.335938 L 356.892578 71.412109 L 363.729980 71.412109 L 363.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 363.892578 72.335938 L 363.892578 71.412109 L 370.729980 71.412109 L 370.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 370.892578 72.335938 L 370.892578 71.412109 L 377.729980 71.412109 L 377.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 377.892578 72.335938 L 377.892578 71.412109 L 384.729980 71.412109 L 384.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 384.892578 72.335938 L 384.892578 71.412109 L 391.729980 71.412109 L 391.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 391.892578 72.335938 L 391.892578 71.412109 L 398.729980 71.412109 L 398.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 398.892578 72.335938 L 398.892578 71.412109 L 405.729980 71.412109 L 405.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 405.892578 72.335938 L 405.892578 71.412109 L 412.729980 71.412109 L 412.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 412.892578 72.335938 L 412.892578 71.412109 L 419.729980 71.412109 L 419.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 419.892578 72.335938 L 419.892578 71.412109 L 426.729980 71.412109 L 426.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 426.892578 72.335938 L 426.892578 71.412109 L 433.729980 71.412109 L 433.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 433.892578 72.335938 L 433.892578 71.412109 L 440.729980 71.412109 L 440.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 440.892578 72.335938 L 440.892578 71.412109 L 447.729980 71.412109 L 447.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 447.892578 72.335938 L 447.892578 71.412109 L 454.729980 71.412109 L 454.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 454.892578 72.335938 L 454.892578 71.412109 L 461.729980 71.412109 L 461.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 461.892578 72.335938 L 461.892578 71.412109 L 468.729980 71.412109 L 468.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 468.892578 72.335938 L 468.892578 71.412109 L 475.729980 71.412109 L 475.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 475.892578 72.335938 L 475.892578 71.412109 L 482.729980 71.412109 L 482.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 482.892578 72.335938 L 482.892578 71.412109 L 489.729980 71.412109 L 489.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 489.892578 72.335938 L 489.892578 71.412109 L 492.878906 71.412109 L 492.878906 64.300781 L 493.738281 64.300781 L 493.738281 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 17.297852 28.276367 L 17.297852 29.984375 L 19.542969 29.984375 L 19.542969 30.752441 L 17.297852 30.752441 L 17.297852 34.018066 Q 17.297852 34.684082 17.550293 34.947266 Q 17.802734 35.210449 18.431152 35.210449 L 19.542969 35.210449 L 19.542969 36.000000 L 18.334473 36.000000 Q 17.222656 36.000000 16.766113 35.554199 Q 16.309570 35.108398 16.309570 34.018066 L 16.309570 30.752441 L 14.703613 30.752441 L 14.703613 29.984375 L 16.309570 29.984375 L 16.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 74.592285 40.116211 Q 73.877930 41.340820 73.526123 42.557373 Q 73.174316 43.773926 73.174316 45.009277 Q 73.174316 46.239258 73.526123 47.458496 Q 73.877930 48.677734 74.592285 49.913086 L 73.732910 49.913086 Q 72.921875 48.634766 72.524414 47.423584 Q 72.126953 46.212402 72.126953 45.009277 Q 72.126953 43.811523 72.524414 42.597656 Q 72.921875 41.383789 73.732910 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 177.878906 65.319336 L 177.878906 51.300781 L 178.738281 51.300781 L 178.738281 58.412109 L 181.729980 58.412109 L 181.729980 59.335938 L 178.738281 59.335938 L 178.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 181.892578 59.335938 L 181.892578 58.412109 L 188.729980 58.412109 L 188.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 188.892578 59.335938 L 188.892578 58.412109 L 195.729980 58.412109 L 195.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 195.892578 59.335938 L 195.892578 58.412109 L 202.729980 58.412109 L 202.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 202.892578 59.335938 L 202.892578 58.412109 L 209.729980 58.412109 L 209.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 209.892578 59.335938 L 209.892578 58.412109 L 216.729980 58.412109 L 216.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 216.892578 59.335938 L 216.892578 58.412109 L 223.729980 58.412109 L 223.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 223.892578 59.335938 L 223.892578 58.412109 L 230.729980 58.412109 L 230.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 230.892578 59.335938 L 230.892578 58.412109 L 237.729980 58.412109 L 237.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 238.735840 53.980957 L 239.826172 53.980957 L 239.826172 57.053223 L 242.796387 57.053223 L 242.796387 53.980957 L 243.886719 53.980957 L 243.886719 62.000000 L 242.796387 62.000000 L 242.796387 57.966309 L 239.826172 57.966309 L 239.826172 62.000000 L 238.735840 62.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 248.297852 54.276367 L 248.297852 55.984375 L 250.542969 55.984375 L 250.542969 56.752441 L 248.297852 56.752441 L 248.297852 60.018066 Q 248.297852 60.684082 248.550293 60.947266 Q 248.802734 61.210449 249.431152 61.210449 L 250.542969 61.210449 L 250.542969 62.000000 L 249.334473 62.000000 Q 248.222656 62.000000 247.766113 61.554199 Q 247.309570 61.108398 247.309570 60.018066 L 247.309570 56.752441 L 245.703613 56.752441 L 245.703613 55.984375 L 247.309570 55.984375 L 247.309570 54.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 255.297852 54.276367 L 255.297852 55.984375 L 257.542969 55.984375 L 257.542969 56.752441 L 255.297852 56.752441 L 255.297852 60.018066 Q 255.297852 60.684082 255.550293 60.947266 Q 255.802734 61.210449 256.431152 61.210449 L 257.542969 61.210449 L 257.542969 62.000000 L 256.334473 62.000000 Q 255.222656 62.000000 254.766113 61.554199 Q 254.309570 61.108398 254.309570 60.018066 L 254.309570 56.752441 L 252.703613 56.752441 L 252.703613 55.984375 L 254.309570 55.984375 L 254.309570 54.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 261.014160 61.242676 L 261.014160 64.288086 L 260.020508 64.288086 L 260.020508 55.984375 L 261.014160 55.984375 L 261.014160 56.752441 Q 261.261230 56.306641 261.672119 56.072998 Q 262.083008 55.839355 262.620117 55.839355 Q 263.710449 55.839355 264.330811 56.682617 Q 264.951172 57.525879 264.951172 59.019043 Q 264.951172 60.485352 264.328125 61.320557 Q 263.705078 62.155762 262.620117 62.155762 Q 262.072266 62.155762 261.661377 61.922119 Q 261.250488 61.688477 261.014160 61.242676 Z M 263.914551 58.997559 Q 263.914551 57.848145 263.552002 57.262695 Q 263.189453 56.677246 262.475098 56.677246 Q 261.755371 56.677246 261.384766 57.265381 Q 261.014160 57.853516 261.014160 58.997559 Q 261.014160 60.136230 261.384766 60.727051 Q 261.755371 61.317871 262.475098 61.317871 Q 263.189453 61.317871 263.552002 60.732422 Q 263.914551 60.146973 263.914551 58.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 270.774902 53.980957 L 271.795410 53.980957 L 267.573730 63.020508 L 266.547852 63.020508 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 275.003418 61.086914 L 278.687988 61.086914 L 278.687988 62.000000 L 273.816406 62.000000 L 273.816406 61.086914 Q 274.820801 60.028809 275.572754 59.217773 Q 276.324707 58.406738 276.609375 58.073730 Q 277.146484 57.418457 277.334473 57.012939 Q 277.522461 56.607422 277.522461 56.183105 Q 277.522461 55.511719 277.127686 55.130371 Q 276.732910 54.749023 276.045410 54.749023 Q 275.556641 54.749023 275.019531 54.926270 Q 274.482422 55.103516 273.880859 55.463379 L 273.880859 54.367676 Q 274.434082 54.104492 274.968506 53.970215 Q 275.502930 53.835938 276.023926 53.835938 Q 277.200195 53.835938 277.917236 54.461670 Q 278.634277 55.087402 278.634277 56.102539 Q 278.634277 56.618164 278.395264 57.133789 Q 278.156250 57.649414 277.619141 58.272461 Q 277.318359 58.621582 276.746338 59.239258 Q 276.174316 59.856934 275.003418 61.086914 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 279.892578 59.335938 L 279.892578 58.412109 L 286.729980 58.412109 L 286.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 286.892578 59.335938 L 286.892578 58.412109 L 293.729980 58.412109 L 293.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 293.892578 59.335938 L 293.892578 58.412109 L 300.729980 58.412109 L 300.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 300.892578 59.335938 L 300.892578 58.412109 L 307.729980 58.412109 L 307.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 307.892578 59.335938 L 307.892578 58.412109 L 314.729980 58.412109 L 314.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 315.644531 61.505859 L 320.365723 59.142578 L 315.644531 56.779297 Z M 315.032227 62.418945 L 315.032227 55.866211 L 321.590332 59.142578 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 65.319336 L 492.878906 51.300781 L 493.738281 51.300781 L 493.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 81.082031 45.213379 Q 81.500977 45.320801 81.796387 45.618896 Q 82.091797 45.916992 82.532227 46.808594 L 83.622559 49.000000 L 82.457031 49.000000 L 81.500977 46.975098 Q 81.087402 46.110352 80.757080 45.860596 Q 80.426758 45.610840 79.895020 45.610840 L 78.858398 45.610840 L 78.858398 49.000000 L 77.768066 49.000000 L 77.768066 40.980957 L 80.002441 40.980957 Q 81.323730 40.980957 82.027344 41.577148 Q 82.730957 42.173340 82.730957 43.301270 Q 82.730957 44.096191 82.298584 44.598389 Q 81.866211 45.100586 81.082031 45.213379 Z M 78.858398 41.872559 L 78.858398 44.719238 L 80.045410 44.719238 Q 80.824219 44.719238 81.205566 44.370117 Q 81.586914 44.020996 81.586914 43.301270 Q 81.586914 42.608398 81.181396 42.240479 Q 80.775879 41.872559 80.002441 41.872559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 85.047363 46.722656 L 85.047363 42.995117 L 86.035645 42.995117 L 86.035645 46.722656 Q 86.035645 47.533691 86.322998 47.915039 Q 86.610352 48.296387 87.211914 48.296387 Q 87.910156 48.296387 88.280762 47.804932 Q 88.651367 47.313477 88.651367 46.395020 L 88.651367 42.995117 L 89.645020 42.995117 L 89.645020 49.000000 L 88.651367 49.000000 L 88.651367 48.097656 Q 88.388184 48.618652 87.934326 48.887207 Q 87.480469 49.155762 86.873535 49.155762 Q 85.949707 49.155762 85.498535 48.551514 Q 85.047363 47.947266 85.047363 46.722656 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 96.226074 43.193848 L 96.226074 44.160645 Q 95.801758 43.913574 95.372070 43.790039 Q 94.942383 43.666504 94.496582 43.666504 Q 93.825195 43.666504 93.494873 43.884033 Q 93.164551 44.101562 93.164551 44.547363 Q 93.164551 44.950195 93.411621 45.148926 Q 93.658691 45.347656 94.641602 45.535645 L 95.039062 45.610840 Q 95.774902 45.750488 96.153564 46.169434 Q 96.532227 46.588379 96.532227 47.259766 Q 96.532227 48.151367 95.898438 48.653564 Q 95.264648 49.155762 94.136719 49.155762 Q 93.690918 49.155762 93.202148 49.061768 Q 92.713379 48.967773 92.144043 48.779785 L 92.144043 47.759277 Q 92.697266 48.043945 93.202148 48.186279 Q 93.707031 48.328613 94.158203 48.328613 Q 94.813477 48.328613 95.173340 48.062744 Q 95.533203 47.796875 95.533203 47.318848 Q 95.533203 46.631348 94.217285 46.368164 L 94.174316 46.357422 L 93.803711 46.282227 Q 92.949707 46.115723 92.557617 45.720947 Q 92.165527 45.326172 92.165527 44.644043 Q 92.165527 43.779297 92.750977 43.309326 Q 93.336426 42.839355 94.421387 42.839355 Q 94.904785 42.839355 95.350586 42.927979 Q 95.796387 43.016602 96.226074 43.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 101.297852 41.276367 L 101.297852 42.984375 L 103.542969 42.984375 L 103.542969 43.752441 L 101.297852 43.752441 L 101.297852 47.018066 Q 101.297852 47.684082 101.550293 47.947266 Q 101.802734 48.210449 102.431152 48.210449 L 103.542969 48.210449 L 103.542969 49.000000 L 102.334473 49.000000 Q 101.222656 49.000000 100.766113 48.554199 Q 100.309570 48.108398 100.309570 47.018066 L 100.309570 43.752441 L 98.703613 43.752441 L 98.703613 42.984375 L 100.309570 42.984375 L 100.309570 41.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 106.869141 40.116211 L 107.728516 40.116211 Q 108.539551 41.383789 108.937012 42.597656 Q 109.334473 43.811523 109.334473 45.009277 Q 109.334473 46.217773 108.937012 47.431641 Q 108.539551 48.645508 107.728516 49.913086 L 106.869141 49.913086 Q 107.583496 48.666992 107.935303 47.447754 Q 108.287109 46.228516 108.287109 45.009277 Q 108.287109 43.784668 107.935303 42.565430 Q 107.583496 41.346191 106.869141 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 177.878906 52.319336 L 177.878906 38.300781 L 178.738281 38.300781 L 178.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 52.319336 L 317.878906 38.300781 L 318.738281 38.300781 L 318.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 389.592285 40.116211 Q 388.877930 41.340820 388.526123 42.557373 Q 388.174316 43.773926 388.174316 45.009277 Q 388.174316 46.239258 388.526123 47.458496 Q 388.877930 48.677734 389.592285 49.913086 L 388.732910 49.913086 Q 387.921875 48.634766 387.524414 47.423584 Q 387.126953 46.212402 387.126953 45.009277 Q 387.126953 43.811523 387.524414 42.597656 Q 387.921875 41.383789 388.732910 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 392.585449 48.672363 L 392.585449 47.404785 Q 393.074219 47.839844 393.595215 48.057373 Q 394.116211 48.274902 394.674805 48.274902 Q 395.442871 48.274902 395.746338 47.874756 Q 396.049805 47.474609 396.049805 46.384277 L 396.049805 41.894043 L 394.003418 41.894043 L 394.003418 40.980957 L 397.134766 40.980957 L 397.134766 46.384277 Q 397.134766 47.898926 396.568115 48.527344 Q 396.001465 49.155762 394.674805 49.155762 Q 394.159180 49.155762 393.648926 49.037598 Q 393.138672 48.919434 392.585449 48.672363 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 402.770508 45.976074 L 402.442871 45.976074 Q 401.578125 45.976074 401.140381 46.279541 Q 400.702637 46.583008 400.702637 47.184570 Q 400.702637 47.727051 401.030273 48.027832 Q 401.357910 48.328613 401.937988 48.328613 Q 402.754395 48.328613 403.221680 47.761963 Q 403.688965 47.195312 403.694336 46.196289 L 403.694336 45.976074 Z M 404.687988 45.567871 L 404.687988 49.000000 L 403.694336 49.000000 L 403.694336 48.108398 Q 403.377441 48.645508 402.896729 48.900635 Q 402.416016 49.155762 401.728516 49.155762 Q 400.810059 49.155762 400.262207 48.637451 Q 399.714355 48.119141 399.714355 47.249023 Q 399.714355 46.244629 400.388428 45.723633 Q 401.062500 45.202637 402.367676 45.202637 L 403.694336 45.202637 L 403.694336 45.046875 Q 403.688965 44.327148 403.329102 44.002197 Q 402.969238 43.677246 402.179688 43.677246 Q 401.674805 43.677246 401.159180 43.822266 Q 400.643555 43.967285 400.154785 44.246582 L 400.154785 43.258301 Q 400.702637 43.048828 401.204834 42.944092 Q 401.707031 42.839355 402.179688 42.839355 Q 402.926270 42.839355 403.455322 43.059570 Q 403.984375 43.279785 404.312012 43.720215 Q 404.516113 43.988770 404.602051 44.383545 Q 404.687988 44.778320 404.687988 45.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 406.537109 42.984375 L 407.562988 42.984375 L 409.308594 48.033203 L 411.059570 42.984375 L 412.085449 42.984375 L 409.947754 49.000000 L 408.674805 49.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 416.770508 45.976074 L 416.442871 45.976074 Q 415.578125 45.976074 415.140381 46.279541 Q 414.702637 46.583008 414.702637 47.184570 Q 414.702637 47.727051 415.030273 48.027832 Q 415.357910 48.328613 415.937988 48.328613 Q 416.754395 48.328613 417.221680 47.761963 Q 417.688965 47.195312 417.694336 46.196289 L 417.694336 45.976074 Z M 418.687988 45.567871 L 418.687988 49.000000 L 417.694336 49.000000 L 417.694336 48.108398 Q 417.377441 48.645508 416.896729 48.900635 Q 416.416016 49.155762 415.728516 49.155762 Q 414.810059 49.155762 414.262207 48.637451 Q 413.714355 48.119141 413.714355 47.249023 Q 413.714355 46.244629 414.388428 45.723633 Q 415.062500 45.202637 416.367676 45.202637 L 417.694336 45.202637 L 417.694336 45.046875 Q 417.688965 44.327148 417.329102 44.002197 Q 416.969238 43.677246 416.179688 43.677246 Q 415.674805 43.677246 415.159180 43.822266 Q 414.643555 43.967285 414.154785 44.246582 L 414.154785 43.258301 Q 414.702637 43.048828 415.204834 42.944092 Q 415.707031 42.839355 416.179688 42.839355 Q 416.926270 42.839355 417.455322 43.059570 Q 417.984375 43.279785 418.312012 43.720215 Q 418.516113 43.988770 418.602051 44.383545 Q 418.687988 44.778320 418.687988 45.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 421.869141 40.116211 L 422.728516 40.116211 Q 423.539551 41.383789 423.937012 42.597656 Q 424.334473 43.811523 424.334473 45.009277 Q 424.334473 46.217773 423.937012 47.431641 Q 423.539551 48.645508 422.728516 49.913086 L 421.869141 49.913086 Q 422.583496 48.666992 422.935303 47.447754 Q 423.287109 46.228516 423.287109 45.009277 Q 423.287109 43.784668 422.935303 42.565430 Q 422.583496 41.346191 421.869141 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 52.319336 L 492.878906 38.300781 L 493.738281 38.300781 L 493.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 27.015625 32.498047 L 27.015625 32.981445 L 22.734863 32.981445 L 22.734863 33.013672 Q 22.691895 34.243652 23.204834 34.780762 Q 23.717773 35.317871 24.652344 35.317871 Q 25.125000 35.317871 25.640625 35.167480 Q 26.156250 35.017090 26.741699 34.710938 L 26.741699 35.693848 Q 26.177734 35.924805 25.654053 36.040283 Q 25.130371 36.155762 24.641602 36.155762 Q 23.239746 36.155762 22.450195 35.315186 Q 21.660645 34.474609 21.660645 32.997559 Q 21.660645 31.558105 22.434082 30.698730 Q 23.207520 29.839355 24.496582 29.839355 Q 25.645996 29.839355 26.309326 30.618164 Q 26.972656 31.396973 27.015625 32.498047 Z M 26.027344 32.208008 Q 25.962891 31.584961 25.573486 31.131104 Q 25.184082 30.677246 24.453613 30.677246 Q 23.739258 30.677246 23.277344 31.149902 Q 22.815430 31.622559 22.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 33.226074 30.193848 L 33.226074 31.160645 Q 32.801758 30.913574 32.372070 30.790039 Q 31.942383 30.666504 31.496582 30.666504 Q 30.825195 30.666504 30.494873 30.884033 Q 30.164551 31.101562 30.164551 31.547363 Q 30.164551 31.950195 30.411621 32.148926 Q 30.658691 32.347656 31.641602 32.535645 L 32.039062 32.610840 Q 32.774902 32.750488 33.153564 33.169434 Q 33.532227 33.588379 33.532227 34.259766 Q 33.532227 35.151367 32.898438 35.653564 Q 32.264648 36.155762 31.136719 36.155762 Q 30.690918 36.155762 30.202148 36.061768 Q 29.713379 35.967773 29.144043 35.779785 L 29.144043 34.759277 Q 29.697266 35.043945 30.202148 35.186279 Q 30.707031 35.328613 31.158203 35.328613 Q 31.813477 35.328613 32.173340 35.062744 Q 32.533203 34.796875 32.533203 34.318848 Q 32.533203 33.631348 31.217285 33.368164 L 31.174316 33.357422 L 30.803711 33.282227 Q 29.949707 33.115723 29.557617 32.720947 Q 29.165527 32.326172 29.165527 31.644043 Q 29.165527 30.779297 29.750977 30.309326 Q 30.336426 29.839355 31.421387 29.839355 Q 31.904785 29.839355 32.350586 29.927979 Q 32.796387 30.016602 33.226074 30.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 38.297852 28.276367 L 38.297852 29.984375 L 40.542969 29.984375 L 40.542969 30.752441 L 38.297852 30.752441 L 38.297852 34.018066 Q 38.297852 34.684082 38.550293 34.947266 Q 38.802734 35.210449 39.431152 35.210449 L 40.542969 35.210449 L 40.542969 36.000000 L 39.334473 36.000000 Q 38.222656 36.000000 37.766113 35.554199 Q 37.309570 35.108398 37.309570 34.018066 L 37.309570 30.752441 L 35.703613 30.752441 L 35.703613 29.984375 L 37.309570 29.984375 L 37.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 44.089355 33.830078 L 45.979980 33.830078 L 45.979980 36.000000 L 44.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 50.697266 30.005859 L 53.227051 30.005859 L 53.227051 35.231934 L 55.187500 35.231934 L 55.187500 36.000000 L 50.278320 36.000000 L 50.278320 35.231934 L 52.238770 35.231934 L 52.238770 30.773926 L 50.697266 30.773926 Z M 52.238770 27.669434 L 53.227051 27.669434 L 53.227051 28.915527 L 52.238770 28.915527 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 59.861816 33.824707 Q 59.861816 34.490723 60.106201 34.829102 Q 60.350586 35.167480 60.828613 35.167480 L 61.983398 35.167480 L 61.983398 36.000000 L 60.731934 36.000000 Q 59.845703 36.000000 59.359619 35.433350 Q 58.873535 34.866699 58.873535 33.824707 L 58.873535 28.383789 L 57.289062 28.383789 L 57.289062 27.610352 L 59.861816 27.610352 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 65.014160 35.242676 L 65.014160 38.288086 L 64.020508 38.288086 L 64.020508 29.984375 L 65.014160 29.984375 L 65.014160 30.752441 Q 65.261230 30.306641 65.672119 30.072998 Q 66.083008 29.839355 66.620117 29.839355 Q 67.710449 29.839355 68.330811 30.682617 Q 68.951172 31.525879 68.951172 33.019043 Q 68.951172 34.485352 68.328125 35.320557 Q 67.705078 36.155762 66.620117 36.155762 Q 66.072266 36.155762 65.661377 35.922119 Q 65.250488 35.688477 65.014160 35.242676 Z M 67.914551 32.997559 Q 67.914551 31.848145 67.552002 31.262695 Q 67.189453 30.677246 66.475098 30.677246 Q 65.755371 30.677246 65.384766 31.265381 Q 65.014160 31.853516 65.014160 32.997559 Q 65.014160 34.136230 65.384766 34.727051 Q 65.755371 35.317871 66.475098 35.317871 Q 67.189453 35.317871 67.552002 34.732422 Q 67.914551 34.146973 67.914551 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 70.537109 29.984375 L 71.562988 29.984375 L 73.308594 35.033203 L 75.059570 29.984375 L 76.085449 29.984375 L 73.947754 36.000000 L 72.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 80.947754 28.969238 L 78.417969 33.207031 L 80.947754 33.207031 Z M 80.770508 27.980957 L 82.027344 27.980957 L 82.027344 33.207031 L 83.096191 33.207031 L 83.096191 34.087891 L 82.027344 34.087891 L 82.027344 36.000000 L 80.947754 36.000000 L 80.947754 34.087891 L 77.547852 34.087891 L 77.547852 33.062012 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 84.547852 32.111328 L 90.241211 32.111328 L 90.241211 33.019043 L 84.547852 33.019043 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 95.608398 30.752441 L 95.608398 27.642578 L 96.596680 27.642578 L 96.596680 36.000000 L 95.608398 36.000000 L 95.608398 35.242676 Q 95.361328 35.688477 94.950439 35.922119 Q 94.539551 36.155762 94.002441 36.155762 Q 92.912109 36.155762 92.286377 35.309814 Q 91.660645 34.463867 91.660645 32.976074 Q 91.660645 31.509766 92.289062 30.674561 Q 92.917480 29.839355 94.002441 29.839355 Q 94.544922 29.839355 94.958496 30.072998 Q 95.372070 30.306641 95.608398 30.752441 Z M 92.702637 32.997559 Q 92.702637 34.146973 93.067871 34.732422 Q 93.433105 35.317871 94.147461 35.317871 Q 94.861816 35.317871 95.235107 34.727051 Q 95.608398 34.136230 95.608398 32.997559 Q 95.608398 31.853516 95.235107 31.265381 Q 94.861816 30.677246 94.147461 30.677246 Q 93.433105 30.677246 93.067871 31.262695 Q 92.702637 31.848145 92.702637 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 104.015625 32.498047 L 104.015625 32.981445 L 99.734863 32.981445 L 99.734863 33.013672 Q 99.691895 34.243652 100.204834 34.780762 Q 100.717773 35.317871 101.652344 35.317871 Q 102.125000 35.317871 102.640625 35.167480 Q 103.156250 35.017090 103.741699 34.710938 L 103.741699 35.693848 Q 103.177734 35.924805 102.654053 36.040283 Q 102.130371 36.155762 101.641602 36.155762 Q 100.239746 36.155762 99.450195 35.315186 Q 98.660645 34.474609 98.660645 32.997559 Q 98.660645 31.558105 99.434082 30.698730 Q 100.207520 29.839355 101.496582 29.839355 Q 102.645996 29.839355 103.309326 30.618164 Q 103.972656 31.396973 104.015625 32.498047 Z M 103.027344 32.208008 Q 102.962891 31.584961 102.573486 31.131104 Q 102.184082 30.677246 101.453613 30.677246 Q 100.739258 30.677246 100.277344 31.149902 Q 99.815430 31.622559 99.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 105.537109 29.984375 L 106.562988 29.984375 L 108.308594 35.033203 L 110.059570 29.984375 L 111.085449 29.984375 L 108.947754 36.000000 L 107.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 114.089355 33.830078 L 115.979980 33.830078 L 115.979980 36.000000 L 114.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 125.015625 32.498047 L 125.015625 32.981445 L 120.734863 32.981445 L 120.734863 33.013672 Q 120.691895 34.243652 121.204834 34.780762 Q 121.717773 35.317871 122.652344 35.317871 Q 123.125000 35.317871 123.640625 35.167480 Q 124.156250 35.017090 124.741699 34.710938 L 124.741699 35.693848 Q 124.177734 35.924805 123.654053 36.040283 Q 123.130371 36.155762 122.641602 36.155762 Q 121.239746 36.155762 120.450195 35.315186 Q 119.660645 34.474609 119.660645 32.997559 Q 119.660645 31.558105 120.434082 30.698730 Q 121.207520 29.839355 122.496582 29.839355 Q 123.645996 29.839355 124.309326 30.618164 Q 124.972656 31.396973 125.015625 32.498047 Z M 124.027344 32.208008 Q 123.962891 31.584961 123.573486 31.131104 Q 123.184082 30.677246 122.453613 30.677246 Q 121.739258 30.677246 121.277344 31.149902 Q 120.815430 31.622559 120.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 129.630859 30.596680 Q 129.813477 30.209961 130.095459 30.024658 Q 130.377441 29.839355 130.774902 29.839355 Q 131.500000 29.839355 131.798096 30.400635 Q 132.096191 30.961914 132.096191 32.514160 L 132.096191 36.000000 L 131.193848 36.000000 L 131.193848 32.557129 Q 131.193848 31.284180 131.051514 30.975342 Q 130.909180 30.666504 130.533203 30.666504 Q 130.103516 30.666504 129.945068 30.996826 Q 129.786621 31.327148 129.786621 32.557129 L 129.786621 36.000000 L 128.884277 36.000000 L 128.884277 32.557129 Q 128.884277 31.268066 128.731201 30.967285 Q 128.578125 30.666504 128.180664 30.666504 Q 127.788574 30.666504 127.635498 30.996826 Q 127.482422 31.327148 127.482422 32.557129 L 127.482422 36.000000 L 126.585449 36.000000 L 126.585449 29.984375 L 127.482422 29.984375 L 127.482422 30.500000 Q 127.659668 30.177734 127.925537 30.008545 Q 128.191406 29.839355 128.529785 29.839355 Q 128.937988 29.839355 129.209229 30.027344 Q 129.480469 30.215332 129.630859 30.596680 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 136.630859 30.596680 Q 136.813477 30.209961 137.095459 30.024658 Q 137.377441 29.839355 137.774902 29.839355 Q 138.500000 29.839355 138.798096 30.400635 Q 139.096191 30.961914 139.096191 32.514160 L 139.096191 36.000000 L 138.193848 36.000000 L 138.193848 32.557129 Q 138.193848 31.284180 138.051514 30.975342 Q 137.909180 30.666504 137.533203 30.666504 Q 137.103516 30.666504 136.945068 30.996826 Q 136.786621 31.327148 136.786621 32.557129 L 136.786621 36.000000 L 135.884277 36.000000 L 135.884277 32.557129 Q 135.884277 31.268066 135.731201 30.967285 Q 135.578125 30.666504 135.180664 30.666504 Q 134.788574 30.666504 134.635498 30.996826 Q 134.482422 31.327148 134.482422 32.557129 L 134.482422 36.000000 L 133.585449 36.000000 L 133.585449 29.984375 L 134.482422 29.984375 L 134.482422 30.500000 Q 134.659668 30.177734 134.925537 30.008545 Q 135.191406 29.839355 135.529785 29.839355 Q 135.937988 29.839355 136.209229 30.027344 Q 136.480469 30.215332 136.630859 30.596680 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.015625 32.498047 L 146.015625 32.981445 L 141.734863 32.981445 L 141.734863 33.013672 Q 141.691895 34.243652 142.204834 34.780762 Q 142.717773 35.317871 143.652344 35.317871 Q 144.125000 35.317871 144.640625 35.167480 Q 145.156250 35.017090 145.741699 34.710938 L 145.741699 35.693848 Q 145.177734 35.924805 144.654053 36.040283 Q 144.130371 36.155762 143.641602 36.155762 Q 142.239746 36.155762 141.450195 35.315186 Q 140.660645 34.474609 140.660645 32.997559 Q 140.660645 31.558105 141.434082 30.698730 Q 142.207520 29.839355 143.496582 29.839355 Q 144.645996 29.839355 145.309326 30.618164 Q 145.972656 31.396973 146.015625 32.498047 Z M 145.027344 32.208008 Q 144.962891 31.584961 144.573486 31.131104 Q 144.184082 30.677246 143.453613 30.677246 Q 142.739258 30.677246 142.277344 31.149902 Q 141.815430 31.622559 141.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 150.297852 28.276367 L 150.297852 29.984375 L 152.542969 29.984375 L 152.542969 30.752441 L 150.297852 30.752441 L 150.297852 34.018066 Q 150.297852 34.684082 150.550293 34.947266 Q 150.802734 35.210449 151.431152 35.210449 L 152.542969 35.210449 L 152.542969 36.000000 L 151.334473 36.000000 Q 150.222656 36.000000 149.766113 35.554199 Q 149.309570 35.108398 149.309570 34.018066 L 149.309570 30.752441 L 147.703613 30.752441 L 147.703613 29.984375 L 149.309570 29.984375 L 149.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 157.297852 28.276367 L 157.297852 29.984375 L 159.542969 29.984375 L 159.542969 30.752441 L 157.297852 30.752441 L 157.297852 34.018066 Q 157.297852 34.684082 157.550293 34.947266 Q 157.802734 35.210449 158.431152 35.210449 L 159.542969 35.210449 L 159.542969 36.000000 L 158.334473 36.000000 Q 157.222656 36.000000 156.766113 35.554199 Q 156.309570 35.108398 156.309570 34.018066 L 156.309570 30.752441 L 154.703613 30.752441 L 154.703613 29.984375 L 156.309570 29.984375 L 156.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 177.878906 39.319336 L 177.878906 25.300781 L 178.738281 25.300781 L 178.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 39.319336 L 317.878906 25.300781 L 318.738281 25.300781 L 318.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 339.297852 28.276367 L 339.297852 29.984375 L 341.542969 29.984375 L 341.542969 30.752441 L 339.297852 30.752441 L 339.297852 34.018066 Q 339.297852 34.684082 339.550293 34.947266 Q 339.802734 35.210449 340.431152 35.210449 L 341.542969 35.210449 L 341.542969 36.000000 L 340.334473 36.000000 Q 339.222656 36.000000 338.766113 35.554199 Q 338.309570 35.108398 338.309570 34.018066 L 338.309570 30.752441 L 336.703613 30.752441 L 336.703613 29.984375 L 338.309570 29.984375 L 338.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 349.015625 32.498047 L 349.015625 32.981445 L 344.734863 32.981445 L 344.734863 33.013672 Q 344.691895 34.243652 345.204834 34.780762 Q 345.717773 35.317871 346.652344 35.317871 Q 347.125000 35.317871 347.640625 35.167480 Q 348.156250 35.017090 348.741699 34.710938 L 348.741699 35.693848 Q 348.177734 35.924805 347.654053 36.040283 Q 347.130371 36.155762 346.641602 36.155762 Q 345.239746 36.155762 344.450195 35.315186 Q 343.660645 34.474609 343.660645 32.997559 Q 343.660645 31.558105 344.434082 30.698730 Q 345.207520 29.839355 346.496582 29.839355 Q 347.645996 29.839355 348.309326 30.618164 Q 348.972656 31.396973 349.015625 32.498047 Z M 348.027344 32.208008 Q 347.962891 31.584961 347.573486 31.131104 Q 347.184082 30.677246 346.453613 30.677246 Q 345.739258 30.677246 345.277344 31.149902 Q 344.815430 31.622559 344.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 355.226074 30.193848 L 355.226074 31.160645 Q 354.801758 30.913574 354.372070 30.790039 Q 353.942383 30.666504 353.496582 30.666504 Q 352.825195 30.666504 352.494873 30.884033 Q 352.164551 31.101562 352.164551 31.547363 Q 352.164551 31.950195 352.411621 32.148926 Q 352.658691 32.347656 353.641602 32.535645 L 354.039062 32.610840 Q 354.774902 32.750488 355.153564 33.169434 Q 355.532227 33.588379 355.532227 34.259766 Q 355.532227 35.151367 354.898438 35.653564 Q 354.264648 36.155762 353.136719 36.155762 Q 352.690918 36.155762 352.202148 36.061768 Q 351.713379 35.967773 351.144043 35.779785 L 351.144043 34.759277 Q 351.697266 35.043945 352.202148 35.186279 Q 352.707031 35.328613 353.158203 35.328613 Q 353.813477 35.328613 354.173340 35.062744 Q 354.533203 34.796875 354.533203 34.318848 Q 354.533203 33.631348 353.217285 33.368164 L 353.174316 33.357422 L 352.803711 33.282227 Q 351.949707 33.115723 351.557617 32.720947 Q 351.165527 32.326172 351.165527 31.644043 Q 351.165527 30.779297 351.750977 30.309326 Q 352.336426 29.839355 353.421387 29.839355 Q 353.904785 29.839355 354.350586 29.927979 Q 354.796387 30.016602 355.226074 30.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 360.297852 28.276367 L 360.297852 29.984375 L 362.542969 29.984375 L 362.542969 30.752441 L 360.297852 30.752441 L 360.297852 34.018066 Q 360.297852 34.684082 360.550293 34.947266 Q 360.802734 35.210449 361.431152 35.210449 L 362.542969 35.210449 L 362.542969 36.000000 L 361.334473 36.000000 Q 360.222656 36.000000 359.766113 35.554199 Q 359.309570 35.108398 359.309570 34.018066 L 359.309570 30.752441 L 357.703613 30.752441 L 357.703613 29.984375 L 359.309570 29.984375 L 359.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 366.089355 33.830078 L 367.979980 33.830078 L 367.979980 36.000000 L 366.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 372.697266 30.005859 L 375.227051 30.005859 L 375.227051 35.231934 L 377.187500 35.231934 L 377.187500 36.000000 L 372.278320 36.000000 L 372.278320 35.231934 L 374.238770 35.231934 L 374.238770 30.773926 L 372.697266 30.773926 Z M 374.238770 27.669434 L 375.227051 27.669434 L 375.227051 28.915527 L 374.238770 28.915527 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 381.861816 33.824707 Q 381.861816 34.490723 382.106201 34.829102 Q 382.350586 35.167480 382.828613 35.167480 L 383.983398 35.167480 L 383.983398 36.000000 L 382.731934 36.000000 Q 381.845703 36.000000 381.359619 35.433350 Q 380.873535 34.866699 380.873535 33.824707 L 380.873535 28.383789 L 379.289062 28.383789 L 379.289062 27.610352 L 381.861816 27.610352 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 387.014160 35.242676 L 387.014160 38.288086 L 386.020508 38.288086 L 386.020508 29.984375 L 387.014160 29.984375 L 387.014160 30.752441 Q 387.261230 30.306641 387.672119 30.072998 Q 388.083008 29.839355 388.620117 29.839355 Q 389.710449 29.839355 390.330811 30.682617 Q 390.951172 31.525879 390.951172 33.019043 Q 390.951172 34.485352 390.328125 35.320557 Q 389.705078 36.155762 388.620117 36.155762 Q 388.072266 36.155762 387.661377 35.922119 Q 387.250488 35.688477 387.014160 35.242676 Z M 389.914551 32.997559 Q 389.914551 31.848145 389.552002 31.262695 Q 389.189453 30.677246 388.475098 30.677246 Q 387.755371 30.677246 387.384766 31.265381 Q 387.014160 31.853516 387.014160 32.997559 Q 387.014160 34.136230 387.384766 34.727051 Q 387.755371 35.317871 388.475098 35.317871 Q 389.189453 35.317871 389.552002 34.732422 Q 389.914551 34.146973 389.914551 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 392.537109 29.984375 L 393.562988 29.984375 L 395.308594 35.033203 L 397.059570 29.984375 L 398.085449 29.984375 L 395.947754 36.000000 L 394.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 402.947754 28.969238 L 400.417969 33.207031 L 402.947754 33.207031 Z M 402.770508 27.980957 L 404.027344 27.980957 L 404.027344 33.207031 L 405.096191 33.207031 L 405.096191 34.087891 L 404.027344 34.087891 L 404.027344 36.000000 L 402.947754 36.000000 L 402.947754 34.087891 L 399.547852 34.087891 L 399.547852 33.062012 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 406.547852 32.111328 L 412.241211 32.111328 L 412.241211 33.019043 L 406.547852 33.019043 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 417.608398 30.752441 L 417.608398 27.642578 L 418.596680 27.642578 L 418.596680 36.000000 L 417.608398 36.000000 L 417.608398 35.242676 Q 417.361328 35.688477 416.950439 35.922119 Q 416.539551 36.155762 416.002441 36.155762 Q 414.912109 36.155762 414.286377 35.309814 Q 413.660645 34.463867 413.660645 32.976074 Q 413.660645 31.509766 414.289062 30.674561 Q 414.917480 29.839355 416.002441 29.839355 Q 416.544922 29.839355 416.958496 30.072998 Q 417.372070 30.306641 417.608398 30.752441 Z M 414.702637 32.997559 Q 414.702637 34.146973 415.067871 34.732422 Q 415.433105 35.317871 416.147461 35.317871 Q 416.861816 35.317871 417.235107 34.727051 Q 417.608398 34.136230 417.608398 32.997559 Q 417.608398 31.853516 417.235107 31.265381 Q 416.861816 30.677246 416.147461 30.677246 Q 415.433105 30.677246 415.067871 31.262695 Q 414.702637 31.848145 414.702637 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 426.015625 32.498047 L 426.015625 32.981445 L 421.734863 32.981445 L 421.734863 33.013672 Q 421.691895 34.243652 422.204834 34.780762 Q 422.717773 35.317871 423.652344 35.317871 Q 424.125000 35.317871 424.640625 35.167480 Q 425.156250 35.017090 425.741699 34.710938 L 425.741699 35.693848 Q 425.177734 35.924805 424.654053 36.040283 Q 424.130371 36.155762 423.641602 36.155762 Q 422.239746 36.155762 421.450195 35.315186 Q 420.660645 34.474609 420.660645 32.997559 Q 420.660645 31.558105 421.434082 30.698730 Q 422.207520 29.839355 423.496582 29.839355 Q 424.645996 29.839355 425.309326 30.618164 Q 425.972656 31.396973 426.015625 32.498047 Z M 425.027344 32.208008 Q 424.962891 31.584961 424.573486 31.131104 Q 424.184082 30.677246 423.453613 30.677246 Q 422.739258 30.677246 422.277344 31.149902 Q 421.815430 31.622559 421.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 427.537109 29.984375 L 428.562988 29.984375 L 430.308594 35.033203 L 432.059570 29.984375 L 433.085449 29.984375 L 430.947754 36.000000 L 429.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 436.089355 33.830078 L 437.979980 33.830078 L 437.979980 36.000000 L 436.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 444.630859 30.596680 Q 444.813477 30.209961 445.095459 30.024658 Q 445.377441 29.839355 445.774902 29.839355 Q 446.500000 29.839355 446.798096 30.400635 Q 447.096191 30.961914 447.096191 32.514160 L 447.096191 36.000000 L 446.193848 36.000000 L 446.193848 32.557129 Q 446.193848 31.284180 446.051514 30.975342 Q 445.909180 30.666504 445.533203 30.666504 Q 445.103516 30.666504 444.945068 30.996826 Q 444.786621 31.327148 444.786621 32.557129 L 444.786621 36.000000 L 443.884277 36.000000 L 443.884277 32.557129 Q 443.884277 31.268066 443.731201 30.967285 Q 443.578125 30.666504 443.180664 30.666504 Q 442.788574 30.666504 442.635498 30.996826 Q 442.482422 31.327148 442.482422 32.557129 L 442.482422 36.000000 L 441.585449 36.000000 L 441.585449 29.984375 L 442.482422 29.984375 L 442.482422 30.500000 Q 442.659668 30.177734 442.925537 30.008545 Q 443.191406 29.839355 443.529785 29.839355 Q 443.937988 29.839355 444.209229 30.027344 Q 444.480469 30.215332 444.630859 30.596680 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 451.770508 32.976074 L 451.442871 32.976074 Q 450.578125 32.976074 450.140381 33.279541 Q 449.702637 33.583008 449.702637 34.184570 Q 449.702637 34.727051 450.030273 35.027832 Q 450.357910 35.328613 450.937988 35.328613 Q 451.754395 35.328613 452.221680 34.761963 Q 452.688965 34.195312 452.694336 33.196289 L 452.694336 32.976074 Z M 453.687988 32.567871 L 453.687988 36.000000 L 452.694336 36.000000 L 452.694336 35.108398 Q 452.377441 35.645508 451.896729 35.900635 Q 451.416016 36.155762 450.728516 36.155762 Q 449.810059 36.155762 449.262207 35.637451 Q 448.714355 35.119141 448.714355 34.249023 Q 448.714355 33.244629 449.388428 32.723633 Q 450.062500 32.202637 451.367676 32.202637 L 452.694336 32.202637 L 452.694336 32.046875 Q 452.688965 31.327148 452.329102 31.002197 Q 451.969238 30.677246 451.179688 30.677246 Q 450.674805 30.677246 450.159180 30.822266 Q 449.643555 30.967285 449.154785 31.246582 L 449.154785 30.258301 Q 449.702637 30.048828 450.204834 29.944092 Q 450.707031 29.839355 451.179688 29.839355 Q 451.926270 29.839355 452.455322 30.059570 Q 452.984375 30.279785 453.312012 30.720215 Q 453.516113 30.988770 453.602051 31.383545 Q 453.687988 31.778320 453.687988 32.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 461.203613 31.225098 Q 460.886719 30.978027 460.559082 30.865234 Q 460.231445 30.752441 459.839355 30.752441 Q 458.915527 30.752441 458.426758 31.332520 Q 457.937988 31.912598 457.937988 33.008301 L 457.937988 36.000000 L 456.944336 36.000000 L 456.944336 29.984375 L 457.937988 29.984375 L 457.937988 31.160645 Q 458.185059 30.521484 458.697998 30.180420 Q 459.210938 29.839355 459.914551 29.839355 Q 460.279785 29.839355 460.596680 29.930664 Q 460.913574 30.021973 461.203613 30.215332 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 465.297852 28.276367 L 465.297852 29.984375 L 467.542969 29.984375 L 467.542969 30.752441 L 465.297852 30.752441 L 465.297852 34.018066 Q 465.297852 34.684082 465.550293 34.947266 Q 465.802734 35.210449 466.431152 35.210449 L 467.542969 35.210449 L 467.542969 36.000000 L 466.334473 36.000000 Q 465.222656 36.000000 464.766113 35.554199 Q 464.309570 35.108398 464.309570 34.018066 L 464.309570 30.752441 L 462.703613 30.752441 L 462.703613 29.984375 L 464.309570 29.984375 L 464.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 473.608398 34.066406 Q 473.361328 34.694824 472.979980 35.720703 Q 472.448242 37.138672 472.265625 37.450195 Q 472.018555 37.869141 471.647949 38.078613 Q 471.277344 38.288086 470.783203 38.288086 L 469.988281 38.288086 L 469.988281 37.460938 L 470.573730 37.460938 Q 471.008789 37.460938 471.255859 37.208496 Q 471.502930 36.956055 471.884277 35.903320 L 469.558594 29.984375 L 470.605957 29.984375 L 472.389160 34.689453 L 474.145508 29.984375 L 475.192871 29.984375 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 39.319336 L 492.878906 25.300781 L 493.738281 25.300781 L 493.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 176.256836 20.142578 L 180.978027 22.505859 L 180.978027 17.779297 Z M 175.032227 20.142578 L 181.590332 16.866211 L 181.590332 23.418945 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 181.892578 20.335938 L 181.892578 19.412109 L 188.729980 19.412109 L 188.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 188.892578 20.335938 L 188.892578 19.412109 L 195.729980 19.412109 L 195.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 195.892578 20.335938 L 195.892578 19.412109 L 202.729980 19.412109 L 202.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 202.892578 20.335938 L 202.892578 19.412109 L 209.729980 19.412109 L 209.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 209.892578 20.335938 L 209.892578 19.412109 L 216.729980 19.412109 L 216.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 217.735840 14.980957 L 218.826172 14.980957 L 218.826172 18.053223 L 221.796387 18.053223 L 221.796387 14.980957 L 222.886719 14.980957 L 222.886719 23.000000 L 221.796387 23.000000 L 221.796387 18.966309 L 218.826172 18.966309 L 218.826172 23.000000 L 217.735840 23.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 227.297852 15.276367 L 227.297852 16.984375 L 229.542969 16.984375 L 229.542969 17.752441 L 227.297852 17.752441 L 227.297852 21.018066 Q 227.297852 21.684082 227.550293 21.947266 Q 227.802734 22.210449 228.431152 22.210449 L 229.542969 22.210449 L 229.542969 23.000000 L 228.334473 23.000000 Q 227.222656 23.000000 226.766113 22.554199 Q 226.309570 22.108398 226.309570 21.018066 L 226.309570 17.752441 L 224.703613 17.752441 L 224.703613 16.984375 L 226.309570 16.984375 L 226.309570 15.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 234.297852 15.276367 L 234.297852 16.984375 L 236.542969 16.984375 L 236.542969 17.752441 L 234.297852 17.752441 L 234.297852 21.018066 Q 234.297852 21.684082 234.550293 21.947266 Q 234.802734 22.210449 235.431152 22.210449 L 236.542969 22.210449 L 236.542969 23.000000 L 235.334473 23.000000 Q 234.222656 23.000000 233.766113 22.554199 Q 233.309570 22.108398 233.309570 21.018066 L 233.309570 17.752441 L 231.703613 17.752441 L 231.703613 16.984375 L 233.309570 16.984375 L 233.309570 15.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 240.014160 22.242676 L 240.014160 25.288086 L 239.020508 25.288086 L 239.020508 16.984375 L 240.014160 16.984375 L 240.014160 17.752441 Q 240.261230 17.306641 240.672119 17.072998 Q 241.083008 16.839355 241.620117 16.839355 Q 242.710449 16.839355 243.330811 17.682617 Q 243.951172 18.525879 243.951172 20.019043 Q 243.951172 21.485352 243.328125 22.320557 Q 242.705078 23.155762 241.620117 23.155762 Q 241.072266 23.155762 240.661377 22.922119 Q 240.250488 22.688477 240.014160 22.242676 Z M 242.914551 19.997559 Q 242.914551 18.848145 242.552002 18.262695 Q 242.189453 17.677246 241.475098 17.677246 Q 240.755371 17.677246 240.384766 18.265381 Q 240.014160 18.853516 240.014160 19.997559 Q 240.014160 21.136230 240.384766 21.727051 Q 240.755371 22.317871 241.475098 22.317871 Q 242.189453 22.317871 242.552002 21.732422 Q 242.914551 21.146973 242.914551 19.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 249.774902 14.980957 L 250.795410 14.980957 L 246.573730 24.020508 L 245.547852 24.020508 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 254.003418 22.086914 L 257.687988 22.086914 L 257.687988 23.000000 L 252.816406 23.000000 L 252.816406 22.086914 Q 253.820801 21.028809 254.572754 20.217773 Q 255.324707 19.406738 255.609375 19.073730 Q 256.146484 18.418457 256.334473 18.012939 Q 256.522461 17.607422 256.522461 17.183105 Q 256.522461 16.511719 256.127686 16.130371 Q 255.732910 15.749023 255.045410 15.749023 Q 254.556641 15.749023 254.019531 15.926270 Q 253.482422 16.103516 252.880859 16.463379 L 252.880859 15.367676 Q 253.434082 15.104492 253.968506 14.970215 Q 254.502930 14.835938 255.023926 14.835938 Q 256.200195 14.835938 256.917236 15.461670 Q 257.634277 16.087402 257.634277 17.102539 Q 257.634277 17.618164 257.395264 18.133789 Q 257.156250 18.649414 256.619141 19.272461 Q 256.318359 19.621582 255.746338 20.239258 Q 255.174316 20.856934 254.003418 22.086914 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 258.892578 20.335938 L 258.892578 19.412109 L 265.729980 19.412109 L 265.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 265.892578 20.335938 L 265.892578 19.412109 L 272.729980 19.412109 L 272.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 272.892578 20.335938 L 272.892578 19.412109 L 279.729980 19.412109 L 279.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 279.892578 20.335938 L 279.892578 19.412109 L 286.729980 19.412109 L 286.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 286.892578 20.335938 L 286.892578 19.412109 L 293.729980 19.412109 L 293.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 293.892578 20.335938 L 293.892578 19.412109 L 300.729980 19.412109 L 300.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 300.892578 20.335938 L 300.892578 19.412109 L 307.729980 19.412109 L 307.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 307.892578 20.335938 L 307.892578 19.412109 L 314.729980 19.412109 L 314.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 26.319336 L 317.878906 20.335938 L 314.892578 20.335938 L 314.892578 19.412109 L 317.878906 19.412109 L 317.878906 12.300781 L 318.738281 12.300781 L 318.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 26.319336 L 492.878906 12.300781 L 493.738281 12.300781 L 493.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 6.892578 7.335938 L 6.892578 6.412109 L 13.729980 6.412109 L 13.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 13.892578 7.335938 L 13.892578 6.412109 L 20.729980 6.412109 L 20.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 20.892578 7.335938 L 20.892578 6.412109 L 27.729980 6.412109 L 27.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 27.892578 7.335938 L 27.892578 6.412109 L 34.729980 6.412109 L 34.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 34.892578 7.335938 L 34.892578 6.412109 L 41.729980 6.412109 L 41.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 41.892578 7.335938 L 41.892578 6.412109 L 48.729980 6.412109 L 48.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 48.892578 7.335938 L 48.892578 6.412109 L 55.729980 6.412109 L 55.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 55.892578 7.335938 L 55.892578 6.412109 L 62.729980 6.412109 L 62.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 62.892578 7.335938 L 62.892578 6.412109 L 69.729980 6.412109 L 69.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 69.892578 7.335938 L 69.892578 6.412109 L 76.729980 6.412109 L 76.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 76.892578 7.335938 L 76.892578 6.412109 L 83.729980 6.412109 L 83.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 83.892578 7.335938 L 83.892578 6.412109 L 90.729980 6.412109 L 90.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 90.892578 7.335938 L 90.892578 6.412109 L 97.729980 6.412109 L 97.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 97.892578 7.335938 L 97.892578 6.412109 L 104.729980 6.412109 L 104.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 104.892578 7.335938 L 104.892578 6.412109 L 111.729980 6.412109 L 111.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 111.892578 7.335938 L 111.892578 6.412109 L 118.729980 6.412109 L 118.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 118.892578 7.335938 L 118.892578 6.412109 L 125.729980 6.412109 L 125.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 125.892578 7.335938 L 125.892578 6.412109 L 132.729980 6.412109 L 132.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 132.892578 7.335938 L 132.892578 6.412109 L 139.729980 6.412109 L 139.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 139.892578 7.335938 L 139.892578 6.412109 L 146.729980 6.412109 L 146.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.892578 7.335938 L 146.892578 6.412109 L 153.729980 6.412109 L 153.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 153.892578 7.335938 L 153.892578 6.412109 L 160.729980 6.412109 L 160.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 160.892578 7.335938 L 160.892578 6.412109 L 167.729980 6.412109 L 167.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 167.892578 7.335938 L 167.892578 6.412109 L 174.729980 6.412109 L 174.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 177.878906 13.319336 L 177.878906 7.335938 L 174.892578 7.335938 L 174.892578 6.412109 L 178.738281 6.412109 L 178.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 13.319336 L 317.878906 6.412109 L 321.729980 6.412109 L 321.729980 7.335938 L 318.738281 7.335938 L 318.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 321.892578 7.335938 L 321.892578 6.412109 L 328.729980 6.412109 L 328.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 328.892578 7.335938 L 328.892578 6.412109 L 335.729980 6.412109 L 335.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 335.892578 7.335938 L 335.892578 6.412109 L 342.729980 6.412109 L 342.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 342.892578 7.335938 L 342.892578 6.412109 L 349.729980 6.412109 L 349.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 349.892578 7.335938 L 349.892578 6.412109 L 356.729980 6.412109 L 356.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 356.892578 7.335938 L 356.892578 6.412109 L 363.729980 6.412109 L 363.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 363.892578 7.335938 L 363.892578 6.412109 L 370.729980 6.412109 L 370.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 370.892578 7.335938 L 370.892578 6.412109 L 377.729980 6.412109 L 377.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 377.892578 7.335938 L 377.892578 6.412109 L 384.729980 6.412109 L 384.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 384.892578 7.335938 L 384.892578 6.412109 L 391.729980 6.412109 L 391.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 391.892578 7.335938 L 391.892578 6.412109 L 398.729980 6.412109 L 398.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 398.892578 7.335938 L 398.892578 6.412109 L 405.729980 6.412109 L 405.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 405.892578 7.335938 L 405.892578 6.412109 L 412.729980 6.412109 L 412.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 412.892578 7.335938 L 412.892578 6.412109 L 419.729980 6.412109 L 419.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 419.892578 7.335938 L 419.892578 6.412109 L 426.729980 6.412109 L 426.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 426.892578 7.335938 L 426.892578 6.412109 L 433.729980 6.412109 L 433.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 433.892578 7.335938 L 433.892578 6.412109 L 440.729980 6.412109 L 440.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 440.892578 7.335938 L 440.892578 6.412109 L 447.729980 6.412109 L 447.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 447.892578 7.335938 L 447.892578 6.412109 L 454.729980 6.412109 L 454.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 454.892578 7.335938 L 454.892578 6.412109 L 461.729980 6.412109 L 461.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 461.892578 7.335938 L 461.892578 6.412109 L 468.729980 6.412109 L 468.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 468.892578 7.335938 L 468.892578 6.412109 L 475.729980 6.412109 L 475.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 475.892578 7.335938 L 475.892578 6.412109 L 482.729980 6.412109 L 482.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 482.892578 7.335938 L 482.892578 6.412109 L 489.729980 6.412109 L 489.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 13.319336 L 492.878906 7.335938 L 489.892578 7.335938 L 489.892578 6.412109 L 493.738281 6.412109 L 493.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
    </svg>
    <p>{`From the perspective of Marty, the following account is defined to enable a connection with Emmett:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "accountId": "emmett-xrp",
  "description": "The peering account for Emmett (on the Marty Connector)",
  "accountRelationship": "PEER",
  "linkType": "BLAST",
  "assetCode": "XRP",
  "assetScale": "6",
  "customSettings": {
    "blast.incoming.auth_type": "JWT_HS_256",
    "blast.incoming.shared_secret": "{cipher_text}",
    "blast.outgoing.auth_type": "SIMPLE",
    "__desc":"Following is the AccountId that identifies Marty at Emmett",
    "blast.outgoing.token_subject":"marty-xrp",
    "blast.outgoing.shared_secret": "{cipher_text}",
    "blast.outgoing.url": "https://emmett-dot-ilpv4-dev.appspot.com/ilp"
  }
  "sendRotues":"true",
  "receiveRotues":"true"
}
`}</code></pre>
    <p>{`When Marty makes an outbound HTTP request to Emmett, each request will have a simple bearer token whose value is the decrypted version of the actual `}<inlineCode parentName="p">{`{cipher_text}`}</inlineCode>{` configured in `}<inlineCode parentName="p">{`blast.outgoing.shared_secret`}</inlineCode>{`. For example, if the actual value is of the decrypted cipher_text is `}<inlineCode parentName="p">{`password`}</inlineCode>{`, then this value would be decrypted at runtime and sent over the wire to Emmett (note this is only a temporary setup until `}<a parentName="p" {...{
        "href": "https://github.com/interledger/rfcs/pull/531"
      }}>{`this PR`}</a>{` is accepted by the community and the Rust implementation is updated).`}</p>
    <p>{`When Marty receives an inbound HTTP request from Emmett, each request will have an `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header containing a simple bearer token. In reality, this token will be a very long-lived JWT that Marty can decode as-if it were a `}<inlineCode parentName="p">{`JWT_HS_256`}</inlineCode>{` compliant token.`}</p>
    <h3 {...{
      "id": "clara"
    }}>{`Clara`}</h3>
    <p>{`Marty peers with `}<a parentName="p" {...{
        "href": "clara-connector"
      }}>{`Clara`}</a>{` using `}<a parentName="p" {...{
        "href": "https://github.com/interledger/rfcs/blob/master/0035-ilp-over-http/0035-ilp-over-http.md"
      }}>{`Ilp-over-Http`}</a>{`.`}</p>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="498" height="79">
      <path d="M 2.878906 13.319336 L 2.878906 6.412109 L 6.729980 6.412109 L 6.729980 7.335938 L 3.738281 7.335938 L 3.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 26.319336 L 2.878906 12.300781 L 3.738281 12.300781 L 3.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 39.319336 L 2.878906 25.300781 L 3.738281 25.300781 L 3.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 52.319336 L 2.878906 38.300781 L 3.738281 38.300781 L 3.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 65.319336 L 2.878906 51.300781 L 3.738281 51.300781 L 3.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 72.335938 L 2.878906 64.300781 L 3.738281 64.300781 L 3.738281 71.412109 L 6.729980 71.412109 L 6.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 6.892578 72.335938 L 6.892578 71.412109 L 13.729980 71.412109 L 13.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 13.892578 72.335938 L 13.892578 71.412109 L 20.729980 71.412109 L 20.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 20.892578 72.335938 L 20.892578 71.412109 L 27.729980 71.412109 L 27.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 27.892578 72.335938 L 27.892578 71.412109 L 34.729980 71.412109 L 34.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 34.892578 72.335938 L 34.892578 71.412109 L 41.729980 71.412109 L 41.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 41.892578 72.335938 L 41.892578 71.412109 L 48.729980 71.412109 L 48.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 48.892578 72.335938 L 48.892578 71.412109 L 55.729980 71.412109 L 55.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 55.892578 72.335938 L 55.892578 71.412109 L 62.729980 71.412109 L 62.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 62.892578 72.335938 L 62.892578 71.412109 L 69.729980 71.412109 L 69.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 69.892578 72.335938 L 69.892578 71.412109 L 76.729980 71.412109 L 76.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 76.892578 72.335938 L 76.892578 71.412109 L 83.729980 71.412109 L 83.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 83.892578 72.335938 L 83.892578 71.412109 L 90.729980 71.412109 L 90.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 90.892578 72.335938 L 90.892578 71.412109 L 97.729980 71.412109 L 97.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 97.892578 72.335938 L 97.892578 71.412109 L 104.729980 71.412109 L 104.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 104.892578 72.335938 L 104.892578 71.412109 L 111.729980 71.412109 L 111.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 111.892578 72.335938 L 111.892578 71.412109 L 118.729980 71.412109 L 118.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 118.892578 72.335938 L 118.892578 71.412109 L 125.729980 71.412109 L 125.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 125.892578 72.335938 L 125.892578 71.412109 L 132.729980 71.412109 L 132.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 132.892578 72.335938 L 132.892578 71.412109 L 139.729980 71.412109 L 139.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 139.892578 72.335938 L 139.892578 71.412109 L 146.729980 71.412109 L 146.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.892578 72.335938 L 146.892578 71.412109 L 153.729980 71.412109 L 153.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 153.892578 72.335938 L 153.892578 71.412109 L 160.729980 71.412109 L 160.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 160.892578 72.335938 L 160.892578 71.412109 L 167.729980 71.412109 L 167.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 167.892578 72.335938 L 167.892578 71.412109 L 174.729980 71.412109 L 174.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 174.892578 72.335938 L 174.892578 71.412109 L 177.878906 71.412109 L 177.878906 64.300781 L 178.738281 64.300781 L 178.738281 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 72.335938 L 317.878906 64.300781 L 318.738281 64.300781 L 318.738281 71.412109 L 321.729980 71.412109 L 321.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 321.892578 72.335938 L 321.892578 71.412109 L 328.729980 71.412109 L 328.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 328.892578 72.335938 L 328.892578 71.412109 L 335.729980 71.412109 L 335.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 335.892578 72.335938 L 335.892578 71.412109 L 342.729980 71.412109 L 342.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 342.892578 72.335938 L 342.892578 71.412109 L 349.729980 71.412109 L 349.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 349.892578 72.335938 L 349.892578 71.412109 L 356.729980 71.412109 L 356.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 356.892578 72.335938 L 356.892578 71.412109 L 363.729980 71.412109 L 363.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 363.892578 72.335938 L 363.892578 71.412109 L 370.729980 71.412109 L 370.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 370.892578 72.335938 L 370.892578 71.412109 L 377.729980 71.412109 L 377.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 377.892578 72.335938 L 377.892578 71.412109 L 384.729980 71.412109 L 384.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 384.892578 72.335938 L 384.892578 71.412109 L 391.729980 71.412109 L 391.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 391.892578 72.335938 L 391.892578 71.412109 L 398.729980 71.412109 L 398.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 398.892578 72.335938 L 398.892578 71.412109 L 405.729980 71.412109 L 405.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 405.892578 72.335938 L 405.892578 71.412109 L 412.729980 71.412109 L 412.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 412.892578 72.335938 L 412.892578 71.412109 L 419.729980 71.412109 L 419.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 419.892578 72.335938 L 419.892578 71.412109 L 426.729980 71.412109 L 426.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 426.892578 72.335938 L 426.892578 71.412109 L 433.729980 71.412109 L 433.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 433.892578 72.335938 L 433.892578 71.412109 L 440.729980 71.412109 L 440.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 440.892578 72.335938 L 440.892578 71.412109 L 447.729980 71.412109 L 447.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 447.892578 72.335938 L 447.892578 71.412109 L 454.729980 71.412109 L 454.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 454.892578 72.335938 L 454.892578 71.412109 L 461.729980 71.412109 L 461.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 461.892578 72.335938 L 461.892578 71.412109 L 468.729980 71.412109 L 468.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 468.892578 72.335938 L 468.892578 71.412109 L 475.729980 71.412109 L 475.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 475.892578 72.335938 L 475.892578 71.412109 L 482.729980 71.412109 L 482.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 482.892578 72.335938 L 482.892578 71.412109 L 489.729980 71.412109 L 489.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 489.892578 72.335938 L 489.892578 71.412109 L 492.878906 71.412109 L 492.878906 64.300781 L 493.738281 64.300781 L 493.738281 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 24.297852 28.276367 L 24.297852 29.984375 L 26.542969 29.984375 L 26.542969 30.752441 L 24.297852 30.752441 L 24.297852 34.018066 Q 24.297852 34.684082 24.550293 34.947266 Q 24.802734 35.210449 25.431152 35.210449 L 26.542969 35.210449 L 26.542969 36.000000 L 25.334473 36.000000 Q 24.222656 36.000000 23.766113 35.554199 Q 23.309570 35.108398 23.309570 34.018066 L 23.309570 30.752441 L 21.703613 30.752441 L 21.703613 29.984375 L 23.309570 29.984375 L 23.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 74.592285 40.116211 Q 73.877930 41.340820 73.526123 42.557373 Q 73.174316 43.773926 73.174316 45.009277 Q 73.174316 46.239258 73.526123 47.458496 Q 73.877930 48.677734 74.592285 49.913086 L 73.732910 49.913086 Q 72.921875 48.634766 72.524414 47.423584 Q 72.126953 46.212402 72.126953 45.009277 Q 72.126953 43.811523 72.524414 42.597656 Q 72.921875 41.383789 73.732910 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 177.878906 65.319336 L 177.878906 51.300781 L 178.738281 51.300781 L 178.738281 58.412109 L 181.729980 58.412109 L 181.729980 59.335938 L 178.738281 59.335938 L 178.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 181.892578 59.335938 L 181.892578 58.412109 L 188.729980 58.412109 L 188.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 188.892578 59.335938 L 188.892578 58.412109 L 195.729980 58.412109 L 195.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 195.892578 59.335938 L 195.892578 58.412109 L 202.729980 58.412109 L 202.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 202.892578 59.335938 L 202.892578 58.412109 L 209.729980 58.412109 L 209.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 209.892578 59.335938 L 209.892578 58.412109 L 216.729980 58.412109 L 216.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 216.892578 59.335938 L 216.892578 58.412109 L 223.729980 58.412109 L 223.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 223.892578 59.335938 L 223.892578 58.412109 L 230.729980 58.412109 L 230.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 230.892578 59.335938 L 230.892578 58.412109 L 237.729980 58.412109 L 237.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 238.735840 53.980957 L 239.826172 53.980957 L 239.826172 57.053223 L 242.796387 57.053223 L 242.796387 53.980957 L 243.886719 53.980957 L 243.886719 62.000000 L 242.796387 62.000000 L 242.796387 57.966309 L 239.826172 57.966309 L 239.826172 62.000000 L 238.735840 62.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 248.297852 54.276367 L 248.297852 55.984375 L 250.542969 55.984375 L 250.542969 56.752441 L 248.297852 56.752441 L 248.297852 60.018066 Q 248.297852 60.684082 248.550293 60.947266 Q 248.802734 61.210449 249.431152 61.210449 L 250.542969 61.210449 L 250.542969 62.000000 L 249.334473 62.000000 Q 248.222656 62.000000 247.766113 61.554199 Q 247.309570 61.108398 247.309570 60.018066 L 247.309570 56.752441 L 245.703613 56.752441 L 245.703613 55.984375 L 247.309570 55.984375 L 247.309570 54.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 255.297852 54.276367 L 255.297852 55.984375 L 257.542969 55.984375 L 257.542969 56.752441 L 255.297852 56.752441 L 255.297852 60.018066 Q 255.297852 60.684082 255.550293 60.947266 Q 255.802734 61.210449 256.431152 61.210449 L 257.542969 61.210449 L 257.542969 62.000000 L 256.334473 62.000000 Q 255.222656 62.000000 254.766113 61.554199 Q 254.309570 61.108398 254.309570 60.018066 L 254.309570 56.752441 L 252.703613 56.752441 L 252.703613 55.984375 L 254.309570 55.984375 L 254.309570 54.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 261.014160 61.242676 L 261.014160 64.288086 L 260.020508 64.288086 L 260.020508 55.984375 L 261.014160 55.984375 L 261.014160 56.752441 Q 261.261230 56.306641 261.672119 56.072998 Q 262.083008 55.839355 262.620117 55.839355 Q 263.710449 55.839355 264.330811 56.682617 Q 264.951172 57.525879 264.951172 59.019043 Q 264.951172 60.485352 264.328125 61.320557 Q 263.705078 62.155762 262.620117 62.155762 Q 262.072266 62.155762 261.661377 61.922119 Q 261.250488 61.688477 261.014160 61.242676 Z M 263.914551 58.997559 Q 263.914551 57.848145 263.552002 57.262695 Q 263.189453 56.677246 262.475098 56.677246 Q 261.755371 56.677246 261.384766 57.265381 Q 261.014160 57.853516 261.014160 58.997559 Q 261.014160 60.136230 261.384766 60.727051 Q 261.755371 61.317871 262.475098 61.317871 Q 263.189453 61.317871 263.552002 60.732422 Q 263.914551 60.146973 263.914551 58.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 270.774902 53.980957 L 271.795410 53.980957 L 267.573730 63.020508 L 266.547852 63.020508 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 275.003418 61.086914 L 278.687988 61.086914 L 278.687988 62.000000 L 273.816406 62.000000 L 273.816406 61.086914 Q 274.820801 60.028809 275.572754 59.217773 Q 276.324707 58.406738 276.609375 58.073730 Q 277.146484 57.418457 277.334473 57.012939 Q 277.522461 56.607422 277.522461 56.183105 Q 277.522461 55.511719 277.127686 55.130371 Q 276.732910 54.749023 276.045410 54.749023 Q 275.556641 54.749023 275.019531 54.926270 Q 274.482422 55.103516 273.880859 55.463379 L 273.880859 54.367676 Q 274.434082 54.104492 274.968506 53.970215 Q 275.502930 53.835938 276.023926 53.835938 Q 277.200195 53.835938 277.917236 54.461670 Q 278.634277 55.087402 278.634277 56.102539 Q 278.634277 56.618164 278.395264 57.133789 Q 278.156250 57.649414 277.619141 58.272461 Q 277.318359 58.621582 276.746338 59.239258 Q 276.174316 59.856934 275.003418 61.086914 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 279.892578 59.335938 L 279.892578 58.412109 L 286.729980 58.412109 L 286.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 286.892578 59.335938 L 286.892578 58.412109 L 293.729980 58.412109 L 293.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 293.892578 59.335938 L 293.892578 58.412109 L 300.729980 58.412109 L 300.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 300.892578 59.335938 L 300.892578 58.412109 L 307.729980 58.412109 L 307.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 307.892578 59.335938 L 307.892578 58.412109 L 314.729980 58.412109 L 314.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 315.644531 61.505859 L 320.365723 59.142578 L 315.644531 56.779297 Z M 315.032227 62.418945 L 315.032227 55.866211 L 321.590332 59.142578 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 65.319336 L 492.878906 51.300781 L 493.738281 51.300781 L 493.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 77.585449 48.672363 L 77.585449 47.404785 Q 78.074219 47.839844 78.595215 48.057373 Q 79.116211 48.274902 79.674805 48.274902 Q 80.442871 48.274902 80.746338 47.874756 Q 81.049805 47.474609 81.049805 46.384277 L 81.049805 41.894043 L 79.003418 41.894043 L 79.003418 40.980957 L 82.134766 40.980957 L 82.134766 46.384277 Q 82.134766 47.898926 81.568115 48.527344 Q 81.001465 49.155762 79.674805 49.155762 Q 79.159180 49.155762 78.648926 49.037598 Q 78.138672 48.919434 77.585449 48.672363 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 87.770508 45.976074 L 87.442871 45.976074 Q 86.578125 45.976074 86.140381 46.279541 Q 85.702637 46.583008 85.702637 47.184570 Q 85.702637 47.727051 86.030273 48.027832 Q 86.357910 48.328613 86.937988 48.328613 Q 87.754395 48.328613 88.221680 47.761963 Q 88.688965 47.195312 88.694336 46.196289 L 88.694336 45.976074 Z M 89.687988 45.567871 L 89.687988 49.000000 L 88.694336 49.000000 L 88.694336 48.108398 Q 88.377441 48.645508 87.896729 48.900635 Q 87.416016 49.155762 86.728516 49.155762 Q 85.810059 49.155762 85.262207 48.637451 Q 84.714355 48.119141 84.714355 47.249023 Q 84.714355 46.244629 85.388428 45.723633 Q 86.062500 45.202637 87.367676 45.202637 L 88.694336 45.202637 L 88.694336 45.046875 Q 88.688965 44.327148 88.329102 44.002197 Q 87.969238 43.677246 87.179688 43.677246 Q 86.674805 43.677246 86.159180 43.822266 Q 85.643555 43.967285 85.154785 44.246582 L 85.154785 43.258301 Q 85.702637 43.048828 86.204834 42.944092 Q 86.707031 42.839355 87.179688 42.839355 Q 87.926270 42.839355 88.455322 43.059570 Q 88.984375 43.279785 89.312012 43.720215 Q 89.516113 43.988770 89.602051 44.383545 Q 89.687988 44.778320 89.687988 45.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 91.537109 42.984375 L 92.562988 42.984375 L 94.308594 48.033203 L 96.059570 42.984375 L 97.085449 42.984375 L 94.947754 49.000000 L 93.674805 49.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 101.770508 45.976074 L 101.442871 45.976074 Q 100.578125 45.976074 100.140381 46.279541 Q 99.702637 46.583008 99.702637 47.184570 Q 99.702637 47.727051 100.030273 48.027832 Q 100.357910 48.328613 100.937988 48.328613 Q 101.754395 48.328613 102.221680 47.761963 Q 102.688965 47.195312 102.694336 46.196289 L 102.694336 45.976074 Z M 103.687988 45.567871 L 103.687988 49.000000 L 102.694336 49.000000 L 102.694336 48.108398 Q 102.377441 48.645508 101.896729 48.900635 Q 101.416016 49.155762 100.728516 49.155762 Q 99.810059 49.155762 99.262207 48.637451 Q 98.714355 48.119141 98.714355 47.249023 Q 98.714355 46.244629 99.388428 45.723633 Q 100.062500 45.202637 101.367676 45.202637 L 102.694336 45.202637 L 102.694336 45.046875 Q 102.688965 44.327148 102.329102 44.002197 Q 101.969238 43.677246 101.179688 43.677246 Q 100.674805 43.677246 100.159180 43.822266 Q 99.643555 43.967285 99.154785 44.246582 L 99.154785 43.258301 Q 99.702637 43.048828 100.204834 42.944092 Q 100.707031 42.839355 101.179688 42.839355 Q 101.926270 42.839355 102.455322 43.059570 Q 102.984375 43.279785 103.312012 43.720215 Q 103.516113 43.988770 103.602051 44.383545 Q 103.687988 44.778320 103.687988 45.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 106.869141 40.116211 L 107.728516 40.116211 Q 108.539551 41.383789 108.937012 42.597656 Q 109.334473 43.811523 109.334473 45.009277 Q 109.334473 46.217773 108.937012 47.431641 Q 108.539551 48.645508 107.728516 49.913086 L 106.869141 49.913086 Q 107.583496 48.666992 107.935303 47.447754 Q 108.287109 46.228516 108.287109 45.009277 Q 108.287109 43.784668 107.935303 42.565430 Q 107.583496 41.346191 106.869141 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 177.878906 52.319336 L 177.878906 38.300781 L 178.738281 38.300781 L 178.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 52.319336 L 317.878906 38.300781 L 318.738281 38.300781 L 318.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 389.592285 40.116211 Q 388.877930 41.340820 388.526123 42.557373 Q 388.174316 43.773926 388.174316 45.009277 Q 388.174316 46.239258 388.526123 47.458496 Q 388.877930 48.677734 389.592285 49.913086 L 388.732910 49.913086 Q 387.921875 48.634766 387.524414 47.423584 Q 387.126953 46.212402 387.126953 45.009277 Q 387.126953 43.811523 387.524414 42.597656 Q 387.921875 41.383789 388.732910 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 396.082031 45.213379 Q 396.500977 45.320801 396.796387 45.618896 Q 397.091797 45.916992 397.532227 46.808594 L 398.622559 49.000000 L 397.457031 49.000000 L 396.500977 46.975098 Q 396.087402 46.110352 395.757080 45.860596 Q 395.426758 45.610840 394.895020 45.610840 L 393.858398 45.610840 L 393.858398 49.000000 L 392.768066 49.000000 L 392.768066 40.980957 L 395.002441 40.980957 Q 396.323730 40.980957 397.027344 41.577148 Q 397.730957 42.173340 397.730957 43.301270 Q 397.730957 44.096191 397.298584 44.598389 Q 396.866211 45.100586 396.082031 45.213379 Z M 393.858398 41.872559 L 393.858398 44.719238 L 395.045410 44.719238 Q 395.824219 44.719238 396.205566 44.370117 Q 396.586914 44.020996 396.586914 43.301270 Q 396.586914 42.608398 396.181396 42.240479 Q 395.775879 41.872559 395.002441 41.872559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 400.047363 46.722656 L 400.047363 42.995117 L 401.035645 42.995117 L 401.035645 46.722656 Q 401.035645 47.533691 401.322998 47.915039 Q 401.610352 48.296387 402.211914 48.296387 Q 402.910156 48.296387 403.280762 47.804932 Q 403.651367 47.313477 403.651367 46.395020 L 403.651367 42.995117 L 404.645020 42.995117 L 404.645020 49.000000 L 403.651367 49.000000 L 403.651367 48.097656 Q 403.388184 48.618652 402.934326 48.887207 Q 402.480469 49.155762 401.873535 49.155762 Q 400.949707 49.155762 400.498535 48.551514 Q 400.047363 47.947266 400.047363 46.722656 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 411.226074 43.193848 L 411.226074 44.160645 Q 410.801758 43.913574 410.372070 43.790039 Q 409.942383 43.666504 409.496582 43.666504 Q 408.825195 43.666504 408.494873 43.884033 Q 408.164551 44.101562 408.164551 44.547363 Q 408.164551 44.950195 408.411621 45.148926 Q 408.658691 45.347656 409.641602 45.535645 L 410.039062 45.610840 Q 410.774902 45.750488 411.153564 46.169434 Q 411.532227 46.588379 411.532227 47.259766 Q 411.532227 48.151367 410.898438 48.653564 Q 410.264648 49.155762 409.136719 49.155762 Q 408.690918 49.155762 408.202148 49.061768 Q 407.713379 48.967773 407.144043 48.779785 L 407.144043 47.759277 Q 407.697266 48.043945 408.202148 48.186279 Q 408.707031 48.328613 409.158203 48.328613 Q 409.813477 48.328613 410.173340 48.062744 Q 410.533203 47.796875 410.533203 47.318848 Q 410.533203 46.631348 409.217285 46.368164 L 409.174316 46.357422 L 408.803711 46.282227 Q 407.949707 46.115723 407.557617 45.720947 Q 407.165527 45.326172 407.165527 44.644043 Q 407.165527 43.779297 407.750977 43.309326 Q 408.336426 42.839355 409.421387 42.839355 Q 409.904785 42.839355 410.350586 42.927979 Q 410.796387 43.016602 411.226074 43.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 416.297852 41.276367 L 416.297852 42.984375 L 418.542969 42.984375 L 418.542969 43.752441 L 416.297852 43.752441 L 416.297852 47.018066 Q 416.297852 47.684082 416.550293 47.947266 Q 416.802734 48.210449 417.431152 48.210449 L 418.542969 48.210449 L 418.542969 49.000000 L 417.334473 49.000000 Q 416.222656 49.000000 415.766113 48.554199 Q 415.309570 48.108398 415.309570 47.018066 L 415.309570 43.752441 L 413.703613 43.752441 L 413.703613 42.984375 L 415.309570 42.984375 L 415.309570 41.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 421.869141 40.116211 L 422.728516 40.116211 Q 423.539551 41.383789 423.937012 42.597656 Q 424.334473 43.811523 424.334473 45.009277 Q 424.334473 46.217773 423.937012 47.431641 Q 423.539551 48.645508 422.728516 49.913086 L 421.869141 49.913086 Q 422.583496 48.666992 422.935303 47.447754 Q 423.287109 46.228516 423.287109 45.009277 Q 423.287109 43.784668 422.935303 42.565430 Q 422.583496 41.346191 421.869141 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 52.319336 L 492.878906 38.300781 L 493.738281 38.300781 L 493.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 34.015625 32.498047 L 34.015625 32.981445 L 29.734863 32.981445 L 29.734863 33.013672 Q 29.691895 34.243652 30.204834 34.780762 Q 30.717773 35.317871 31.652344 35.317871 Q 32.125000 35.317871 32.640625 35.167480 Q 33.156250 35.017090 33.741699 34.710938 L 33.741699 35.693848 Q 33.177734 35.924805 32.654053 36.040283 Q 32.130371 36.155762 31.641602 36.155762 Q 30.239746 36.155762 29.450195 35.315186 Q 28.660645 34.474609 28.660645 32.997559 Q 28.660645 31.558105 29.434082 30.698730 Q 30.207520 29.839355 31.496582 29.839355 Q 32.645996 29.839355 33.309326 30.618164 Q 33.972656 31.396973 34.015625 32.498047 Z M 33.027344 32.208008 Q 32.962891 31.584961 32.573486 31.131104 Q 32.184082 30.677246 31.453613 30.677246 Q 30.739258 30.677246 30.277344 31.149902 Q 29.815430 31.622559 29.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 40.226074 30.193848 L 40.226074 31.160645 Q 39.801758 30.913574 39.372070 30.790039 Q 38.942383 30.666504 38.496582 30.666504 Q 37.825195 30.666504 37.494873 30.884033 Q 37.164551 31.101562 37.164551 31.547363 Q 37.164551 31.950195 37.411621 32.148926 Q 37.658691 32.347656 38.641602 32.535645 L 39.039062 32.610840 Q 39.774902 32.750488 40.153564 33.169434 Q 40.532227 33.588379 40.532227 34.259766 Q 40.532227 35.151367 39.898438 35.653564 Q 39.264648 36.155762 38.136719 36.155762 Q 37.690918 36.155762 37.202148 36.061768 Q 36.713379 35.967773 36.144043 35.779785 L 36.144043 34.759277 Q 36.697266 35.043945 37.202148 35.186279 Q 37.707031 35.328613 38.158203 35.328613 Q 38.813477 35.328613 39.173340 35.062744 Q 39.533203 34.796875 39.533203 34.318848 Q 39.533203 33.631348 38.217285 33.368164 L 38.174316 33.357422 L 37.803711 33.282227 Q 36.949707 33.115723 36.557617 32.720947 Q 36.165527 32.326172 36.165527 31.644043 Q 36.165527 30.779297 36.750977 30.309326 Q 37.336426 29.839355 38.421387 29.839355 Q 38.904785 29.839355 39.350586 29.927979 Q 39.796387 30.016602 40.226074 30.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 45.297852 28.276367 L 45.297852 29.984375 L 47.542969 29.984375 L 47.542969 30.752441 L 45.297852 30.752441 L 45.297852 34.018066 Q 45.297852 34.684082 45.550293 34.947266 Q 45.802734 35.210449 46.431152 35.210449 L 47.542969 35.210449 L 47.542969 36.000000 L 46.334473 36.000000 Q 45.222656 36.000000 44.766113 35.554199 Q 44.309570 35.108398 44.309570 34.018066 L 44.309570 30.752441 L 42.703613 30.752441 L 42.703613 29.984375 L 44.309570 29.984375 L 44.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 51.089355 33.830078 L 52.979980 33.830078 L 52.979980 36.000000 L 51.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 57.697266 30.005859 L 60.227051 30.005859 L 60.227051 35.231934 L 62.187500 35.231934 L 62.187500 36.000000 L 57.278320 36.000000 L 57.278320 35.231934 L 59.238770 35.231934 L 59.238770 30.773926 L 57.697266 30.773926 Z M 59.238770 27.669434 L 60.227051 27.669434 L 60.227051 28.915527 L 59.238770 28.915527 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 66.861816 33.824707 Q 66.861816 34.490723 67.106201 34.829102 Q 67.350586 35.167480 67.828613 35.167480 L 68.983398 35.167480 L 68.983398 36.000000 L 67.731934 36.000000 Q 66.845703 36.000000 66.359619 35.433350 Q 65.873535 34.866699 65.873535 33.824707 L 65.873535 28.383789 L 64.289062 28.383789 L 64.289062 27.610352 L 66.861816 27.610352 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 72.014160 35.242676 L 72.014160 38.288086 L 71.020508 38.288086 L 71.020508 29.984375 L 72.014160 29.984375 L 72.014160 30.752441 Q 72.261230 30.306641 72.672119 30.072998 Q 73.083008 29.839355 73.620117 29.839355 Q 74.710449 29.839355 75.330811 30.682617 Q 75.951172 31.525879 75.951172 33.019043 Q 75.951172 34.485352 75.328125 35.320557 Q 74.705078 36.155762 73.620117 36.155762 Q 73.072266 36.155762 72.661377 35.922119 Q 72.250488 35.688477 72.014160 35.242676 Z M 74.914551 32.997559 Q 74.914551 31.848145 74.552002 31.262695 Q 74.189453 30.677246 73.475098 30.677246 Q 72.755371 30.677246 72.384766 31.265381 Q 72.014160 31.853516 72.014160 32.997559 Q 72.014160 34.136230 72.384766 34.727051 Q 72.755371 35.317871 73.475098 35.317871 Q 74.189453 35.317871 74.552002 34.732422 Q 74.914551 34.146973 74.914551 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 77.537109 29.984375 L 78.562988 29.984375 L 80.308594 35.033203 L 82.059570 29.984375 L 83.085449 29.984375 L 80.947754 36.000000 L 79.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 87.947754 28.969238 L 85.417969 33.207031 L 87.947754 33.207031 Z M 87.770508 27.980957 L 89.027344 27.980957 L 89.027344 33.207031 L 90.096191 33.207031 L 90.096191 34.087891 L 89.027344 34.087891 L 89.027344 36.000000 L 87.947754 36.000000 L 87.947754 34.087891 L 84.547852 34.087891 L 84.547852 33.062012 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 91.547852 32.111328 L 97.241211 32.111328 L 97.241211 33.019043 L 91.547852 33.019043 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 102.608398 30.752441 L 102.608398 27.642578 L 103.596680 27.642578 L 103.596680 36.000000 L 102.608398 36.000000 L 102.608398 35.242676 Q 102.361328 35.688477 101.950439 35.922119 Q 101.539551 36.155762 101.002441 36.155762 Q 99.912109 36.155762 99.286377 35.309814 Q 98.660645 34.463867 98.660645 32.976074 Q 98.660645 31.509766 99.289062 30.674561 Q 99.917480 29.839355 101.002441 29.839355 Q 101.544922 29.839355 101.958496 30.072998 Q 102.372070 30.306641 102.608398 30.752441 Z M 99.702637 32.997559 Q 99.702637 34.146973 100.067871 34.732422 Q 100.433105 35.317871 101.147461 35.317871 Q 101.861816 35.317871 102.235107 34.727051 Q 102.608398 34.136230 102.608398 32.997559 Q 102.608398 31.853516 102.235107 31.265381 Q 101.861816 30.677246 101.147461 30.677246 Q 100.433105 30.677246 100.067871 31.262695 Q 99.702637 31.848145 99.702637 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 111.015625 32.498047 L 111.015625 32.981445 L 106.734863 32.981445 L 106.734863 33.013672 Q 106.691895 34.243652 107.204834 34.780762 Q 107.717773 35.317871 108.652344 35.317871 Q 109.125000 35.317871 109.640625 35.167480 Q 110.156250 35.017090 110.741699 34.710938 L 110.741699 35.693848 Q 110.177734 35.924805 109.654053 36.040283 Q 109.130371 36.155762 108.641602 36.155762 Q 107.239746 36.155762 106.450195 35.315186 Q 105.660645 34.474609 105.660645 32.997559 Q 105.660645 31.558105 106.434082 30.698730 Q 107.207520 29.839355 108.496582 29.839355 Q 109.645996 29.839355 110.309326 30.618164 Q 110.972656 31.396973 111.015625 32.498047 Z M 110.027344 32.208008 Q 109.962891 31.584961 109.573486 31.131104 Q 109.184082 30.677246 108.453613 30.677246 Q 107.739258 30.677246 107.277344 31.149902 Q 106.815430 31.622559 106.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 112.537109 29.984375 L 113.562988 29.984375 L 115.308594 35.033203 L 117.059570 29.984375 L 118.085449 29.984375 L 115.947754 36.000000 L 114.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 121.089355 33.830078 L 122.979980 33.830078 L 122.979980 36.000000 L 121.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 129.630859 30.596680 Q 129.813477 30.209961 130.095459 30.024658 Q 130.377441 29.839355 130.774902 29.839355 Q 131.500000 29.839355 131.798096 30.400635 Q 132.096191 30.961914 132.096191 32.514160 L 132.096191 36.000000 L 131.193848 36.000000 L 131.193848 32.557129 Q 131.193848 31.284180 131.051514 30.975342 Q 130.909180 30.666504 130.533203 30.666504 Q 130.103516 30.666504 129.945068 30.996826 Q 129.786621 31.327148 129.786621 32.557129 L 129.786621 36.000000 L 128.884277 36.000000 L 128.884277 32.557129 Q 128.884277 31.268066 128.731201 30.967285 Q 128.578125 30.666504 128.180664 30.666504 Q 127.788574 30.666504 127.635498 30.996826 Q 127.482422 31.327148 127.482422 32.557129 L 127.482422 36.000000 L 126.585449 36.000000 L 126.585449 29.984375 L 127.482422 29.984375 L 127.482422 30.500000 Q 127.659668 30.177734 127.925537 30.008545 Q 128.191406 29.839355 128.529785 29.839355 Q 128.937988 29.839355 129.209229 30.027344 Q 129.480469 30.215332 129.630859 30.596680 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 136.770508 32.976074 L 136.442871 32.976074 Q 135.578125 32.976074 135.140381 33.279541 Q 134.702637 33.583008 134.702637 34.184570 Q 134.702637 34.727051 135.030273 35.027832 Q 135.357910 35.328613 135.937988 35.328613 Q 136.754395 35.328613 137.221680 34.761963 Q 137.688965 34.195312 137.694336 33.196289 L 137.694336 32.976074 Z M 138.687988 32.567871 L 138.687988 36.000000 L 137.694336 36.000000 L 137.694336 35.108398 Q 137.377441 35.645508 136.896729 35.900635 Q 136.416016 36.155762 135.728516 36.155762 Q 134.810059 36.155762 134.262207 35.637451 Q 133.714355 35.119141 133.714355 34.249023 Q 133.714355 33.244629 134.388428 32.723633 Q 135.062500 32.202637 136.367676 32.202637 L 137.694336 32.202637 L 137.694336 32.046875 Q 137.688965 31.327148 137.329102 31.002197 Q 136.969238 30.677246 136.179688 30.677246 Q 135.674805 30.677246 135.159180 30.822266 Q 134.643555 30.967285 134.154785 31.246582 L 134.154785 30.258301 Q 134.702637 30.048828 135.204834 29.944092 Q 135.707031 29.839355 136.179688 29.839355 Q 136.926270 29.839355 137.455322 30.059570 Q 137.984375 30.279785 138.312012 30.720215 Q 138.516113 30.988770 138.602051 31.383545 Q 138.687988 31.778320 138.687988 32.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.203613 31.225098 Q 145.886719 30.978027 145.559082 30.865234 Q 145.231445 30.752441 144.839355 30.752441 Q 143.915527 30.752441 143.426758 31.332520 Q 142.937988 31.912598 142.937988 33.008301 L 142.937988 36.000000 L 141.944336 36.000000 L 141.944336 29.984375 L 142.937988 29.984375 L 142.937988 31.160645 Q 143.185059 30.521484 143.697998 30.180420 Q 144.210938 29.839355 144.914551 29.839355 Q 145.279785 29.839355 145.596680 29.930664 Q 145.913574 30.021973 146.203613 30.215332 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 150.297852 28.276367 L 150.297852 29.984375 L 152.542969 29.984375 L 152.542969 30.752441 L 150.297852 30.752441 L 150.297852 34.018066 Q 150.297852 34.684082 150.550293 34.947266 Q 150.802734 35.210449 151.431152 35.210449 L 152.542969 35.210449 L 152.542969 36.000000 L 151.334473 36.000000 Q 150.222656 36.000000 149.766113 35.554199 Q 149.309570 35.108398 149.309570 34.018066 L 149.309570 30.752441 L 147.703613 30.752441 L 147.703613 29.984375 L 149.309570 29.984375 L 149.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 158.608398 34.066406 Q 158.361328 34.694824 157.979980 35.720703 Q 157.448242 37.138672 157.265625 37.450195 Q 157.018555 37.869141 156.647949 38.078613 Q 156.277344 38.288086 155.783203 38.288086 L 154.988281 38.288086 L 154.988281 37.460938 L 155.573730 37.460938 Q 156.008789 37.460938 156.255859 37.208496 Q 156.502930 36.956055 156.884277 35.903320 L 154.558594 29.984375 L 155.605957 29.984375 L 157.389160 34.689453 L 159.145508 29.984375 L 160.192871 29.984375 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 177.878906 39.319336 L 177.878906 25.300781 L 178.738281 25.300781 L 178.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 39.319336 L 317.878906 25.300781 L 318.738281 25.300781 L 318.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 339.297852 28.276367 L 339.297852 29.984375 L 341.542969 29.984375 L 341.542969 30.752441 L 339.297852 30.752441 L 339.297852 34.018066 Q 339.297852 34.684082 339.550293 34.947266 Q 339.802734 35.210449 340.431152 35.210449 L 341.542969 35.210449 L 341.542969 36.000000 L 340.334473 36.000000 Q 339.222656 36.000000 338.766113 35.554199 Q 338.309570 35.108398 338.309570 34.018066 L 338.309570 30.752441 L 336.703613 30.752441 L 336.703613 29.984375 L 338.309570 29.984375 L 338.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 349.015625 32.498047 L 349.015625 32.981445 L 344.734863 32.981445 L 344.734863 33.013672 Q 344.691895 34.243652 345.204834 34.780762 Q 345.717773 35.317871 346.652344 35.317871 Q 347.125000 35.317871 347.640625 35.167480 Q 348.156250 35.017090 348.741699 34.710938 L 348.741699 35.693848 Q 348.177734 35.924805 347.654053 36.040283 Q 347.130371 36.155762 346.641602 36.155762 Q 345.239746 36.155762 344.450195 35.315186 Q 343.660645 34.474609 343.660645 32.997559 Q 343.660645 31.558105 344.434082 30.698730 Q 345.207520 29.839355 346.496582 29.839355 Q 347.645996 29.839355 348.309326 30.618164 Q 348.972656 31.396973 349.015625 32.498047 Z M 348.027344 32.208008 Q 347.962891 31.584961 347.573486 31.131104 Q 347.184082 30.677246 346.453613 30.677246 Q 345.739258 30.677246 345.277344 31.149902 Q 344.815430 31.622559 344.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 355.226074 30.193848 L 355.226074 31.160645 Q 354.801758 30.913574 354.372070 30.790039 Q 353.942383 30.666504 353.496582 30.666504 Q 352.825195 30.666504 352.494873 30.884033 Q 352.164551 31.101562 352.164551 31.547363 Q 352.164551 31.950195 352.411621 32.148926 Q 352.658691 32.347656 353.641602 32.535645 L 354.039062 32.610840 Q 354.774902 32.750488 355.153564 33.169434 Q 355.532227 33.588379 355.532227 34.259766 Q 355.532227 35.151367 354.898438 35.653564 Q 354.264648 36.155762 353.136719 36.155762 Q 352.690918 36.155762 352.202148 36.061768 Q 351.713379 35.967773 351.144043 35.779785 L 351.144043 34.759277 Q 351.697266 35.043945 352.202148 35.186279 Q 352.707031 35.328613 353.158203 35.328613 Q 353.813477 35.328613 354.173340 35.062744 Q 354.533203 34.796875 354.533203 34.318848 Q 354.533203 33.631348 353.217285 33.368164 L 353.174316 33.357422 L 352.803711 33.282227 Q 351.949707 33.115723 351.557617 32.720947 Q 351.165527 32.326172 351.165527 31.644043 Q 351.165527 30.779297 351.750977 30.309326 Q 352.336426 29.839355 353.421387 29.839355 Q 353.904785 29.839355 354.350586 29.927979 Q 354.796387 30.016602 355.226074 30.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 360.297852 28.276367 L 360.297852 29.984375 L 362.542969 29.984375 L 362.542969 30.752441 L 360.297852 30.752441 L 360.297852 34.018066 Q 360.297852 34.684082 360.550293 34.947266 Q 360.802734 35.210449 361.431152 35.210449 L 362.542969 35.210449 L 362.542969 36.000000 L 361.334473 36.000000 Q 360.222656 36.000000 359.766113 35.554199 Q 359.309570 35.108398 359.309570 34.018066 L 359.309570 30.752441 L 357.703613 30.752441 L 357.703613 29.984375 L 359.309570 29.984375 L 359.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 366.089355 33.830078 L 367.979980 33.830078 L 367.979980 36.000000 L 366.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 372.697266 30.005859 L 375.227051 30.005859 L 375.227051 35.231934 L 377.187500 35.231934 L 377.187500 36.000000 L 372.278320 36.000000 L 372.278320 35.231934 L 374.238770 35.231934 L 374.238770 30.773926 L 372.697266 30.773926 Z M 374.238770 27.669434 L 375.227051 27.669434 L 375.227051 28.915527 L 374.238770 28.915527 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 381.861816 33.824707 Q 381.861816 34.490723 382.106201 34.829102 Q 382.350586 35.167480 382.828613 35.167480 L 383.983398 35.167480 L 383.983398 36.000000 L 382.731934 36.000000 Q 381.845703 36.000000 381.359619 35.433350 Q 380.873535 34.866699 380.873535 33.824707 L 380.873535 28.383789 L 379.289062 28.383789 L 379.289062 27.610352 L 381.861816 27.610352 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 387.014160 35.242676 L 387.014160 38.288086 L 386.020508 38.288086 L 386.020508 29.984375 L 387.014160 29.984375 L 387.014160 30.752441 Q 387.261230 30.306641 387.672119 30.072998 Q 388.083008 29.839355 388.620117 29.839355 Q 389.710449 29.839355 390.330811 30.682617 Q 390.951172 31.525879 390.951172 33.019043 Q 390.951172 34.485352 390.328125 35.320557 Q 389.705078 36.155762 388.620117 36.155762 Q 388.072266 36.155762 387.661377 35.922119 Q 387.250488 35.688477 387.014160 35.242676 Z M 389.914551 32.997559 Q 389.914551 31.848145 389.552002 31.262695 Q 389.189453 30.677246 388.475098 30.677246 Q 387.755371 30.677246 387.384766 31.265381 Q 387.014160 31.853516 387.014160 32.997559 Q 387.014160 34.136230 387.384766 34.727051 Q 387.755371 35.317871 388.475098 35.317871 Q 389.189453 35.317871 389.552002 34.732422 Q 389.914551 34.146973 389.914551 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 392.537109 29.984375 L 393.562988 29.984375 L 395.308594 35.033203 L 397.059570 29.984375 L 398.085449 29.984375 L 395.947754 36.000000 L 394.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 402.947754 28.969238 L 400.417969 33.207031 L 402.947754 33.207031 Z M 402.770508 27.980957 L 404.027344 27.980957 L 404.027344 33.207031 L 405.096191 33.207031 L 405.096191 34.087891 L 404.027344 34.087891 L 404.027344 36.000000 L 402.947754 36.000000 L 402.947754 34.087891 L 399.547852 34.087891 L 399.547852 33.062012 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 406.547852 32.111328 L 412.241211 32.111328 L 412.241211 33.019043 L 406.547852 33.019043 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 417.608398 30.752441 L 417.608398 27.642578 L 418.596680 27.642578 L 418.596680 36.000000 L 417.608398 36.000000 L 417.608398 35.242676 Q 417.361328 35.688477 416.950439 35.922119 Q 416.539551 36.155762 416.002441 36.155762 Q 414.912109 36.155762 414.286377 35.309814 Q 413.660645 34.463867 413.660645 32.976074 Q 413.660645 31.509766 414.289062 30.674561 Q 414.917480 29.839355 416.002441 29.839355 Q 416.544922 29.839355 416.958496 30.072998 Q 417.372070 30.306641 417.608398 30.752441 Z M 414.702637 32.997559 Q 414.702637 34.146973 415.067871 34.732422 Q 415.433105 35.317871 416.147461 35.317871 Q 416.861816 35.317871 417.235107 34.727051 Q 417.608398 34.136230 417.608398 32.997559 Q 417.608398 31.853516 417.235107 31.265381 Q 416.861816 30.677246 416.147461 30.677246 Q 415.433105 30.677246 415.067871 31.262695 Q 414.702637 31.848145 414.702637 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 426.015625 32.498047 L 426.015625 32.981445 L 421.734863 32.981445 L 421.734863 33.013672 Q 421.691895 34.243652 422.204834 34.780762 Q 422.717773 35.317871 423.652344 35.317871 Q 424.125000 35.317871 424.640625 35.167480 Q 425.156250 35.017090 425.741699 34.710938 L 425.741699 35.693848 Q 425.177734 35.924805 424.654053 36.040283 Q 424.130371 36.155762 423.641602 36.155762 Q 422.239746 36.155762 421.450195 35.315186 Q 420.660645 34.474609 420.660645 32.997559 Q 420.660645 31.558105 421.434082 30.698730 Q 422.207520 29.839355 423.496582 29.839355 Q 424.645996 29.839355 425.309326 30.618164 Q 425.972656 31.396973 426.015625 32.498047 Z M 425.027344 32.208008 Q 424.962891 31.584961 424.573486 31.131104 Q 424.184082 30.677246 423.453613 30.677246 Q 422.739258 30.677246 422.277344 31.149902 Q 421.815430 31.622559 421.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 427.537109 29.984375 L 428.562988 29.984375 L 430.308594 35.033203 L 432.059570 29.984375 L 433.085449 29.984375 L 430.947754 36.000000 L 429.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 436.089355 33.830078 L 437.979980 33.830078 L 437.979980 36.000000 L 436.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 446.698730 35.693848 Q 446.301270 35.924805 445.879639 36.040283 Q 445.458008 36.155762 445.017578 36.155762 Q 443.621094 36.155762 442.834229 35.317871 Q 442.047363 34.479980 442.047363 32.997559 Q 442.047363 31.515137 442.834229 30.677246 Q 443.621094 29.839355 445.017578 29.839355 Q 445.452637 29.839355 445.866211 29.952148 Q 446.279785 30.064941 446.698730 30.301270 L 446.698730 31.337891 Q 446.306641 30.988770 445.911865 30.833008 Q 445.517090 30.677246 445.017578 30.677246 Q 444.088379 30.677246 443.588867 31.278809 Q 443.089355 31.880371 443.089355 32.997559 Q 443.089355 34.109375 443.591553 34.713623 Q 444.093750 35.317871 445.017578 35.317871 Q 445.533203 35.317871 445.941406 35.159424 Q 446.349609 35.000977 446.698730 34.667969 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 451.861816 33.824707 Q 451.861816 34.490723 452.106201 34.829102 Q 452.350586 35.167480 452.828613 35.167480 L 453.983398 35.167480 L 453.983398 36.000000 L 452.731934 36.000000 Q 451.845703 36.000000 451.359619 35.433350 Q 450.873535 34.866699 450.873535 33.824707 L 450.873535 28.383789 L 449.289062 28.383789 L 449.289062 27.610352 L 451.861816 27.610352 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 458.770508 32.976074 L 458.442871 32.976074 Q 457.578125 32.976074 457.140381 33.279541 Q 456.702637 33.583008 456.702637 34.184570 Q 456.702637 34.727051 457.030273 35.027832 Q 457.357910 35.328613 457.937988 35.328613 Q 458.754395 35.328613 459.221680 34.761963 Q 459.688965 34.195312 459.694336 33.196289 L 459.694336 32.976074 Z M 460.687988 32.567871 L 460.687988 36.000000 L 459.694336 36.000000 L 459.694336 35.108398 Q 459.377441 35.645508 458.896729 35.900635 Q 458.416016 36.155762 457.728516 36.155762 Q 456.810059 36.155762 456.262207 35.637451 Q 455.714355 35.119141 455.714355 34.249023 Q 455.714355 33.244629 456.388428 32.723633 Q 457.062500 32.202637 458.367676 32.202637 L 459.694336 32.202637 L 459.694336 32.046875 Q 459.688965 31.327148 459.329102 31.002197 Q 458.969238 30.677246 458.179688 30.677246 Q 457.674805 30.677246 457.159180 30.822266 Q 456.643555 30.967285 456.154785 31.246582 L 456.154785 30.258301 Q 456.702637 30.048828 457.204834 29.944092 Q 457.707031 29.839355 458.179688 29.839355 Q 458.926270 29.839355 459.455322 30.059570 Q 459.984375 30.279785 460.312012 30.720215 Q 460.516113 30.988770 460.602051 31.383545 Q 460.687988 31.778320 460.687988 32.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 468.203613 31.225098 Q 467.886719 30.978027 467.559082 30.865234 Q 467.231445 30.752441 466.839355 30.752441 Q 465.915527 30.752441 465.426758 31.332520 Q 464.937988 31.912598 464.937988 33.008301 L 464.937988 36.000000 L 463.944336 36.000000 L 463.944336 29.984375 L 464.937988 29.984375 L 464.937988 31.160645 Q 465.185059 30.521484 465.697998 30.180420 Q 466.210938 29.839355 466.914551 29.839355 Q 467.279785 29.839355 467.596680 29.930664 Q 467.913574 30.021973 468.203613 30.215332 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 472.770508 32.976074 L 472.442871 32.976074 Q 471.578125 32.976074 471.140381 33.279541 Q 470.702637 33.583008 470.702637 34.184570 Q 470.702637 34.727051 471.030273 35.027832 Q 471.357910 35.328613 471.937988 35.328613 Q 472.754395 35.328613 473.221680 34.761963 Q 473.688965 34.195312 473.694336 33.196289 L 473.694336 32.976074 Z M 474.687988 32.567871 L 474.687988 36.000000 L 473.694336 36.000000 L 473.694336 35.108398 Q 473.377441 35.645508 472.896729 35.900635 Q 472.416016 36.155762 471.728516 36.155762 Q 470.810059 36.155762 470.262207 35.637451 Q 469.714355 35.119141 469.714355 34.249023 Q 469.714355 33.244629 470.388428 32.723633 Q 471.062500 32.202637 472.367676 32.202637 L 473.694336 32.202637 L 473.694336 32.046875 Q 473.688965 31.327148 473.329102 31.002197 Q 472.969238 30.677246 472.179688 30.677246 Q 471.674805 30.677246 471.159180 30.822266 Q 470.643555 30.967285 470.154785 31.246582 L 470.154785 30.258301 Q 470.702637 30.048828 471.204834 29.944092 Q 471.707031 29.839355 472.179688 29.839355 Q 472.926270 29.839355 473.455322 30.059570 Q 473.984375 30.279785 474.312012 30.720215 Q 474.516113 30.988770 474.602051 31.383545 Q 474.687988 31.778320 474.687988 32.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 39.319336 L 492.878906 25.300781 L 493.738281 25.300781 L 493.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 176.256836 20.142578 L 180.978027 22.505859 L 180.978027 17.779297 Z M 175.032227 20.142578 L 181.590332 16.866211 L 181.590332 23.418945 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 181.892578 20.335938 L 181.892578 19.412109 L 188.729980 19.412109 L 188.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 188.892578 20.335938 L 188.892578 19.412109 L 195.729980 19.412109 L 195.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 195.892578 20.335938 L 195.892578 19.412109 L 202.729980 19.412109 L 202.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 202.892578 20.335938 L 202.892578 19.412109 L 209.729980 19.412109 L 209.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 209.892578 20.335938 L 209.892578 19.412109 L 216.729980 19.412109 L 216.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 217.735840 14.980957 L 218.826172 14.980957 L 218.826172 18.053223 L 221.796387 18.053223 L 221.796387 14.980957 L 222.886719 14.980957 L 222.886719 23.000000 L 221.796387 23.000000 L 221.796387 18.966309 L 218.826172 18.966309 L 218.826172 23.000000 L 217.735840 23.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 227.297852 15.276367 L 227.297852 16.984375 L 229.542969 16.984375 L 229.542969 17.752441 L 227.297852 17.752441 L 227.297852 21.018066 Q 227.297852 21.684082 227.550293 21.947266 Q 227.802734 22.210449 228.431152 22.210449 L 229.542969 22.210449 L 229.542969 23.000000 L 228.334473 23.000000 Q 227.222656 23.000000 226.766113 22.554199 Q 226.309570 22.108398 226.309570 21.018066 L 226.309570 17.752441 L 224.703613 17.752441 L 224.703613 16.984375 L 226.309570 16.984375 L 226.309570 15.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 234.297852 15.276367 L 234.297852 16.984375 L 236.542969 16.984375 L 236.542969 17.752441 L 234.297852 17.752441 L 234.297852 21.018066 Q 234.297852 21.684082 234.550293 21.947266 Q 234.802734 22.210449 235.431152 22.210449 L 236.542969 22.210449 L 236.542969 23.000000 L 235.334473 23.000000 Q 234.222656 23.000000 233.766113 22.554199 Q 233.309570 22.108398 233.309570 21.018066 L 233.309570 17.752441 L 231.703613 17.752441 L 231.703613 16.984375 L 233.309570 16.984375 L 233.309570 15.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 240.014160 22.242676 L 240.014160 25.288086 L 239.020508 25.288086 L 239.020508 16.984375 L 240.014160 16.984375 L 240.014160 17.752441 Q 240.261230 17.306641 240.672119 17.072998 Q 241.083008 16.839355 241.620117 16.839355 Q 242.710449 16.839355 243.330811 17.682617 Q 243.951172 18.525879 243.951172 20.019043 Q 243.951172 21.485352 243.328125 22.320557 Q 242.705078 23.155762 241.620117 23.155762 Q 241.072266 23.155762 240.661377 22.922119 Q 240.250488 22.688477 240.014160 22.242676 Z M 242.914551 19.997559 Q 242.914551 18.848145 242.552002 18.262695 Q 242.189453 17.677246 241.475098 17.677246 Q 240.755371 17.677246 240.384766 18.265381 Q 240.014160 18.853516 240.014160 19.997559 Q 240.014160 21.136230 240.384766 21.727051 Q 240.755371 22.317871 241.475098 22.317871 Q 242.189453 22.317871 242.552002 21.732422 Q 242.914551 21.146973 242.914551 19.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 249.774902 14.980957 L 250.795410 14.980957 L 246.573730 24.020508 L 245.547852 24.020508 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 254.003418 22.086914 L 257.687988 22.086914 L 257.687988 23.000000 L 252.816406 23.000000 L 252.816406 22.086914 Q 253.820801 21.028809 254.572754 20.217773 Q 255.324707 19.406738 255.609375 19.073730 Q 256.146484 18.418457 256.334473 18.012939 Q 256.522461 17.607422 256.522461 17.183105 Q 256.522461 16.511719 256.127686 16.130371 Q 255.732910 15.749023 255.045410 15.749023 Q 254.556641 15.749023 254.019531 15.926270 Q 253.482422 16.103516 252.880859 16.463379 L 252.880859 15.367676 Q 253.434082 15.104492 253.968506 14.970215 Q 254.502930 14.835938 255.023926 14.835938 Q 256.200195 14.835938 256.917236 15.461670 Q 257.634277 16.087402 257.634277 17.102539 Q 257.634277 17.618164 257.395264 18.133789 Q 257.156250 18.649414 256.619141 19.272461 Q 256.318359 19.621582 255.746338 20.239258 Q 255.174316 20.856934 254.003418 22.086914 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 258.892578 20.335938 L 258.892578 19.412109 L 265.729980 19.412109 L 265.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 265.892578 20.335938 L 265.892578 19.412109 L 272.729980 19.412109 L 272.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 272.892578 20.335938 L 272.892578 19.412109 L 279.729980 19.412109 L 279.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 279.892578 20.335938 L 279.892578 19.412109 L 286.729980 19.412109 L 286.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 286.892578 20.335938 L 286.892578 19.412109 L 293.729980 19.412109 L 293.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 293.892578 20.335938 L 293.892578 19.412109 L 300.729980 19.412109 L 300.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 300.892578 20.335938 L 300.892578 19.412109 L 307.729980 19.412109 L 307.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 307.892578 20.335938 L 307.892578 19.412109 L 314.729980 19.412109 L 314.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 26.319336 L 317.878906 20.335938 L 314.892578 20.335938 L 314.892578 19.412109 L 317.878906 19.412109 L 317.878906 12.300781 L 318.738281 12.300781 L 318.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 26.319336 L 492.878906 12.300781 L 493.738281 12.300781 L 493.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 6.892578 7.335938 L 6.892578 6.412109 L 13.729980 6.412109 L 13.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 13.892578 7.335938 L 13.892578 6.412109 L 20.729980 6.412109 L 20.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 20.892578 7.335938 L 20.892578 6.412109 L 27.729980 6.412109 L 27.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 27.892578 7.335938 L 27.892578 6.412109 L 34.729980 6.412109 L 34.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 34.892578 7.335938 L 34.892578 6.412109 L 41.729980 6.412109 L 41.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 41.892578 7.335938 L 41.892578 6.412109 L 48.729980 6.412109 L 48.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 48.892578 7.335938 L 48.892578 6.412109 L 55.729980 6.412109 L 55.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 55.892578 7.335938 L 55.892578 6.412109 L 62.729980 6.412109 L 62.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 62.892578 7.335938 L 62.892578 6.412109 L 69.729980 6.412109 L 69.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 69.892578 7.335938 L 69.892578 6.412109 L 76.729980 6.412109 L 76.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 76.892578 7.335938 L 76.892578 6.412109 L 83.729980 6.412109 L 83.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 83.892578 7.335938 L 83.892578 6.412109 L 90.729980 6.412109 L 90.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 90.892578 7.335938 L 90.892578 6.412109 L 97.729980 6.412109 L 97.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 97.892578 7.335938 L 97.892578 6.412109 L 104.729980 6.412109 L 104.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 104.892578 7.335938 L 104.892578 6.412109 L 111.729980 6.412109 L 111.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 111.892578 7.335938 L 111.892578 6.412109 L 118.729980 6.412109 L 118.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 118.892578 7.335938 L 118.892578 6.412109 L 125.729980 6.412109 L 125.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 125.892578 7.335938 L 125.892578 6.412109 L 132.729980 6.412109 L 132.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 132.892578 7.335938 L 132.892578 6.412109 L 139.729980 6.412109 L 139.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 139.892578 7.335938 L 139.892578 6.412109 L 146.729980 6.412109 L 146.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.892578 7.335938 L 146.892578 6.412109 L 153.729980 6.412109 L 153.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 153.892578 7.335938 L 153.892578 6.412109 L 160.729980 6.412109 L 160.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 160.892578 7.335938 L 160.892578 6.412109 L 167.729980 6.412109 L 167.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 167.892578 7.335938 L 167.892578 6.412109 L 174.729980 6.412109 L 174.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 177.878906 13.319336 L 177.878906 7.335938 L 174.892578 7.335938 L 174.892578 6.412109 L 178.738281 6.412109 L 178.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 317.878906 13.319336 L 317.878906 6.412109 L 321.729980 6.412109 L 321.729980 7.335938 L 318.738281 7.335938 L 318.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 321.892578 7.335938 L 321.892578 6.412109 L 328.729980 6.412109 L 328.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 328.892578 7.335938 L 328.892578 6.412109 L 335.729980 6.412109 L 335.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 335.892578 7.335938 L 335.892578 6.412109 L 342.729980 6.412109 L 342.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 342.892578 7.335938 L 342.892578 6.412109 L 349.729980 6.412109 L 349.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 349.892578 7.335938 L 349.892578 6.412109 L 356.729980 6.412109 L 356.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 356.892578 7.335938 L 356.892578 6.412109 L 363.729980 6.412109 L 363.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 363.892578 7.335938 L 363.892578 6.412109 L 370.729980 6.412109 L 370.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 370.892578 7.335938 L 370.892578 6.412109 L 377.729980 6.412109 L 377.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 377.892578 7.335938 L 377.892578 6.412109 L 384.729980 6.412109 L 384.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 384.892578 7.335938 L 384.892578 6.412109 L 391.729980 6.412109 L 391.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 391.892578 7.335938 L 391.892578 6.412109 L 398.729980 6.412109 L 398.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 398.892578 7.335938 L 398.892578 6.412109 L 405.729980 6.412109 L 405.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 405.892578 7.335938 L 405.892578 6.412109 L 412.729980 6.412109 L 412.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 412.892578 7.335938 L 412.892578 6.412109 L 419.729980 6.412109 L 419.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 419.892578 7.335938 L 419.892578 6.412109 L 426.729980 6.412109 L 426.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 426.892578 7.335938 L 426.892578 6.412109 L 433.729980 6.412109 L 433.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 433.892578 7.335938 L 433.892578 6.412109 L 440.729980 6.412109 L 440.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 440.892578 7.335938 L 440.892578 6.412109 L 447.729980 6.412109 L 447.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 447.892578 7.335938 L 447.892578 6.412109 L 454.729980 6.412109 L 454.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 454.892578 7.335938 L 454.892578 6.412109 L 461.729980 6.412109 L 461.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 461.892578 7.335938 L 461.892578 6.412109 L 468.729980 6.412109 L 468.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 468.892578 7.335938 L 468.892578 6.412109 L 475.729980 6.412109 L 475.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 475.892578 7.335938 L 475.892578 6.412109 L 482.729980 6.412109 L 482.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 482.892578 7.335938 L 482.892578 6.412109 L 489.729980 6.412109 L 489.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 492.878906 13.319336 L 492.878906 7.335938 L 489.892578 7.335938 L 489.892578 6.412109 L 493.738281 6.412109 L 493.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
    </svg>
    <p>{`From the perspective of Marty, the following account is defined to enable a connection with Clara:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "accountId": "clara-xrp",
  "description": "The peering account for Clara (on the Marty Connector)",
  "accountRelationship": "PEER",
  "linkType": "BLAST",
  "assetCode": "XRP",
  "assetScale": "6",
  "customSettings": {
    "blast.incoming.auth_type": "JWT_HS_256",
    "blast.incoming.shared_secret": "{cipher_text}",
    "blast.outgoing.auth_type": "SIMPLE",
    "__desc":"Following is the AccountId that identifies Marty at Clara",
    "blast.outgoing.token_subject":"marty-xrp",
    "blast.outgoing.shared_secret": "{cipher_text}",
    "blast.outgoing.url": "https://clara-dot-ilpv4-dev.appspot.com/ilp"
  }
  "sendRotues":"true",
  "receiveRotues":"true"
}
`}</code></pre>
    <p>{`For more details around the authentication used, see the explanation above under the Emmett account.`}</p>
    <h2 {...{
      "id": "peer-with-marty"
    }}>{`Peer with Marty`}</h2>
    <p>{`Coming soon...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      